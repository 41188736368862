// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { FcGoogle } from "react-icons/fc";
// import { fbLogo } from "../../assets";
// import DataTable from "react-data-table-component";
// import TrafficSourceCell from "./TrafficSourceCell";
// import CustomTitle from "./CustomTitle";
// import CustomSubHeaderComponent from "./CustomSubHeaderComponent";
// import { transformToInternationalNumber, formatNumber } from "../../utils";
// import { BsArrowDown } from "react-icons/bs";
// import { BsArrowUp } from "react-icons/bs";
// import "./styles.css";
// import { LastReportLoader, Popup } from "../../components";
// import { setCompareDate } from "../../redux/reducers/dateSlice";

// const customStyles1 = {
//   subHeader: {
//     style: {
//       backgroundColor: "inherit",
//       color: "white",
//       border: "none",
//       padding: "16px 16px",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-start",
//       gap: "10px",
//     },
//   },
//   headRow: {
//     style: {
//       color: "#b3b703",
//       border: "none",
//       backgroundColor: "#1b1c1e",
//     },
//   },
//   cells: {
//     style: {
//       backgroundColor: "#424448",
//       color: "white",
//     },
//   },
// };

// const columns1 = [
//   {
//     name: "TRAFFIC SOURCE",
//     cell: (row) => <TrafficSourceCell row={row} />,
//     minWidth: "320px",
//     paddingLeft: "20px",
//   },
//   {
//     name: "SPEND",
//     selector: (row) => (
//       <div className="reporting-cell">
//         <span title={row.revenue}>{row.spend}</span>
//         {row.spendComparison !== null && (
//           <span
//             title={row.spendComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.spendComparison === "-"
//                   ? "red"
//                   : typeof row.spendComparison !== "number" ||
//                     row.spendComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.spendComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.spendComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.spendComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.spendComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },

//   {
//     name: "REVENUE",
//     selector: (row) => row.revenue,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.revenue}>{row.revenue}</span>
//         {row.revenueComparison !== null && (
//           <span
//             title={row.revenueComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.revenueComparison === "-"
//                   ? " #FF3838"
//                   : typeof row.revenueComparison !== "number" ||
//                     row.revenueComparison < 0
//                   ? " #FF3838"
//                   : "#00E723",
//             }}
//           >
//             {row.revenueComparison === "-" ? (
//               <span style={{ color: " #FF3838" }}>{"-"}</span>
//             ) : row.revenueComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: " #FF3838" }} />
//                 <span
//                   style={{ color: " #FF3838" }}
//                 >{`(${row.revenueComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.revenueComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "PROFIT",
//     selector: (row) => row.profit,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.profit}>{row.profit}</span>
//         {row.profitComparison !== null && (
//           <span
//             title={row.profitComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.profitComparison === "-"
//                   ? "red"
//                   : typeof row.profitComparison !== "number" ||
//                     row.profitComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.profitComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.profitComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.profitComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.profitComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "SALES",
//     selector: (row) => row?.sales,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.sales}>{row?.sales}</span>
//         {row.salesComparison !== null && (
//           <span
//             title={row.salesComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.salesComparison === "-"
//                   ? "red"
//                   : typeof row.salesComparison !== "number" ||
//                     row.salesComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.salesComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.salesComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.salesComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.salesComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "ROAS",
//     selector: (row) => row.roas,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.roas}>{row.roas}</span>
//         {row.roasComparison !== null && (
//           <span
//             title={row.roasComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.roasComparison === "-"
//                   ? "red"
//                   : typeof row.roasComparison !== "number" ||
//                     row.roasComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.roasComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.roasComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.roasComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.roasComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "CPA",
//     selector: (row) => row.cpa,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.cpa}>{row.cpa == -1 ? "n/a" : row.cpa}</span>
//         {row.cpaComparison !== null && (
//           <span
//             title={row.cpaComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.cpaComparison === "-"
//                   ? "red"
//                   : typeof row.cpaComparison !== "number" ||
//                     row.cpaComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.cpaComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.cpaComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.cpaComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.cpaComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "CPM",
//     selector: (row) => row.cpm,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.cpm}>{row.cpm == -1 ? "n/a" : row.cpm}</span>
//         {row.cpmComparison !== null && (
//           <span
//             title={row.cpmComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.cpmComparison === "-"
//                   ? "red"
//                   : typeof row.cpmComparison !== "number" ||
//                     row.cpmComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.cpmComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.cpmComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.cpmComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.cpmComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "CPC",
//     selector: (row) => row.cpc,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.cpc}>{row.cpc == -1 ? "n/a" : row.cpc}</span>
//         {row.cpcComparison !== null && (
//           <span
//             title={row.cpcComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.cpcComparison === "-"
//                   ? "red"
//                   : typeof row.cpcComparison !== "number" ||
//                     row.cpcComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.cpcComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.cpcComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.cpcComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.cpcComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "CTR %",
//     selector: (row) => row.ctr,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.ctr}>{row.ctr == -1 ? "n/a" : row.ctr}</span>
//         {row.ctrComparison !== null && (
//           <span
//             title={row.ctrComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.ctrComparison === "-"
//                   ? "red"
//                   : typeof row.ctrComparison !== "number" ||
//                     row.ctrComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.ctrComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.ctrComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span
//                   style={{ color: "red" }}
//                 >{`(${row.ctrComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.ctrComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "CR %",
//     selector: (row) => row.cr,
//     cell: (row) => (
//       <div className="reporting-cell">
//         <span title={row.cr}>{row.cr == -1 ? "n/a" : row.cr}</span>
//         {row.crComparison !== null && (
//           <span
//             title={row.crComparison}
//             style={{
//               fontSize: "11px",
//               color:
//                 row.crComparison === "-"
//                   ? "red"
//                   : typeof row.crComparison !== "number" || row.crComparison < 0
//                   ? "red"
//                   : "#00E723",
//             }}
//           >
//             {row.crComparison === "-" ? (
//               <span style={{ color: "red" }}>{"-"}</span>
//             ) : row.crComparison < 0 ? (
//               <>
//                 <BsArrowDown style={{ color: "red" }} />
//                 <span style={{ color: "red" }}>{`(${row.crComparison}%)`}</span>
//               </>
//             ) : (
//               <>
//                 <BsArrowUp style={{ color: "#00E723" }} />
//                 {`${row.crComparison}%`}
//               </>
//             )}
//           </span>
//         )}
//       </div>
//     ),
//   },
// ];

// const LastReport = ({
//   setFilterText,
//   filterText,
//   setFilteredData,
//   setDate,
//   table,
//   attribute,
//   setAttribute,
//   trafficSource,
//   setTrafficSource,
//   setSelectedAdList,
//   maindata,
// }) => {
//   const expandShow = maindata ? maindata[0].campaign.length : 0;
//   console.log(expandShow);

//   const item =
//     Array.isArray(maindata) && maindata.length > 0 ? maindata[0] : {};

//   // Calculate metrics with checks for infinity
//   let cpc = item?.clicks !== 0 ? formatNumber(item.spend / item.clicks) : -1;
//   if (!isFinite(cpc)) cpc = -1;

//   let cpa = item?.sales !== 0 ? formatNumber(item.spend / item.sales) : -1;
//   if (!isFinite(cpa)) cpa = -1;

//   let cr =
//     item?.sales !== 0 ? formatNumber((item.sales * 100) / item.clicks) : -1;
//   if (!isFinite(cr)) cr = -1;

//   let roas = item?.spend !== 0 ? formatNumber(item.revenue / item.spend) : -1;
//   if (!isFinite(roas)) roas = -1;

//   let cpm =
//     item?.impression !== 0
//       ? formatNumber((item.spend * 1000) / item.impression)
//       : -1;
//   if (!isFinite(cpm)) cpm = -1;

//   let ctr =
//     item?.impression !== 0
//       ? formatNumber((item.clicks * 100) / item.impression)
//       : -1;
//   if (!isFinite(ctr)) ctr = -1;

//   // Initialize comparison metrics
//   let compSpend = -1,
//     compRevenue = -1,
//     compSales = -1,
//     compCpa = -1,
//     compProfit = -1,
//     compRoas = -1,
//     compCr = -1,
//     compCpc = -1,
//     compCtr = -1,
//     compCpm = -1;

//   // Access comparison data
//   const compItem =
//     Array.isArray(maindata) && maindata.length > 1 ? maindata[1] : {};

//   // Check and calculate comparison metrics
//   if (Object.keys(compItem).length > 0) {
//     compSpend = compItem.spend || 0;
//     compRevenue = compItem.revenue || 0;
//     compSales = compItem.sales || 0;
//     const compClicks = compItem.clicks || 0;
//     const compImpression = compItem.impression || 0;

//     compCpm =
//       compImpression !== 0
//         ? formatNumber((compSpend * 1000) / compImpression)
//         : -1;
//     compCpa = compSales !== 0 ? formatNumber(compSpend / compSales) : -1;
//     console.log(compCpa);
//     compCpc = compClicks !== 0 ? formatNumber(compSpend / compClicks) : -1;
//     console.log(compClicks);
//     compRoas = compSpend !== 0 ? formatNumber(compRevenue / compSpend) : -1;
//     console.log(compSpend);
//     compCr =
//       compClicks !== 0 ? formatNumber((compSales * 100) / compClicks) : -1;
//     console.log(compCr);
//     compCtr =
//       compImpression !== 0
//         ? formatNumber((compClicks * 100) / compImpression)
//         : -1;
//     compProfit = compRevenue - compSpend;
//   }

//   console.log(compSpend);

//   // Comparison metrics
//   const spendComparison =
//     expandShow > 0
//       ? compSpend !== -1 && compSpend !== 0
//         ? formatNumber((item.spend - compSpend) / compSpend) === 0
//           ? "-"
//           : formatNumber((item.spend - compSpend) / compSpend)
//         : "-"
//       : null; // Added this to handle the case when expandShow is 0

//   console.log(spendComparison);

//   const revenueComparison =
//     expandShow > 0
//       ? compRevenue !== -1 && compRevenue !== 0
//         ? formatNumber((item.revenue - compRevenue) / compRevenue) === 0
//           ? "-"
//           : formatNumber((item.revenue - compRevenue) / compRevenue)
//         : "-"
//       : null;

//   console.log(revenueComparison);

//   const salesComparison =
//     expandShow > 0
//       ? compSales !== -1 && compSales !== 0
//         ? formatNumber((item.sales - compSales) / compSales) === 0
//           ? "-"
//           : formatNumber((item.sales - compSales) / compSales)
//         : "-"
//       : null;

//   const profitComparison =
//     expandShow > 0
//       ? compProfit !== -1 && compProfit !== 0
//         ? formatNumber((item.profit - compProfit) / compProfit) === 0
//           ? "-"
//           : formatNumber((item.revenue - item.spend - compProfit) / compProfit)
//         : "-"
//       : null;

//   const roasComparison =
//     expandShow > 0
//       ? compRoas !== -1 && compRoas !== 0
//         ? formatNumber((roas - compRoas) / compRoas) === 0
//           ? "-"
//           : formatNumber((roas - compRoas) / compRoas)
//         : "-"
//       : null;

//   const cpaComparison =
//     expandShow > 0
//       ? compCpa !== -1 && compCpa !== 0
//         ? formatNumber((cpa - compCpa) / compCpa) === 0
//           ? "-"
//           : formatNumber((cpa - compCpa) / compCpa)
//         : "-"
//       : null;

//   // console.log(cpaComparison);

//   const cpcComparison =
//     expandShow > 0
//       ? compCpc !== -1 && compCpc !== 0
//         ? formatNumber((cpc - compCpc) / compCpc) === 0
//           ? "-"
//           : formatNumber((cpc - compCpc) / compCpc)
//         : "-"
//       : null;

//   const crComparison =
//     expandShow > 0
//       ? compCr !== -1 && compCr !== 0
//         ? formatNumber((cr - compCr) / compCr) === 0
//           ? "-"
//           : formatNumber((cr - compCr) / compCr)
//         : "-"
//       : null;

//   const ctrComparison =
//     expandShow > 0
//       ? compCtr !== -1 && compCpm !== 0
//         ? formatNumber((ctr - compCtr) / compCtr) === 0
//           ? "-"
//           : formatNumber((ctr - compCtr) / compCtr)
//         : "-"
//       : null;

//   const cpmComparison =
//     expandShow > 0
//       ? compCpm !== -1 && compCpm !== 0
//         ? formatNumber((cpm - compCpm) / compCpm) === 0
//           ? "-"
//           : formatNumber((cpm - compCpm) / compCpm)
//         : "-"
//       : null;
//   // Create data1 with formatted values
//   const data1 = [
//     {
//       src:
//         trafficSource === "google" ? (
//           <FcGoogle style={{ width: "16px", height: "16px" }} />
//         ) : (
//           <img
//             src={fbLogo}
//             alt="fbook"
//             style={{ width: "16px", height: "16px" }}
//           />
//         ),
//       trafficSource: trafficSource === "google" ? "Google" : "Facebook",
//       spend: transformToInternationalNumber(formatNumber(item?.spend)),
//       revenue: transformToInternationalNumber(formatNumber(item?.revenue)),
//       profit: transformToInternationalNumber(
//         formatNumber(item?.revenue - item?.spend)
//       ),
//       sales: transformToInternationalNumber(formatNumber(item?.sales)),
//       roas: transformToInternationalNumber(formatNumber(roas)),
//       cpa: transformToInternationalNumber(formatNumber(cpa)),
//       cpm: transformToInternationalNumber(formatNumber(cpm)),
//       cpc: transformToInternationalNumber(formatNumber(cpc)),
//       ctr: transformToInternationalNumber(formatNumber(ctr)),
//       cr: transformToInternationalNumber(formatNumber(cr)),

//       // Comparison metrics
//       spendComparison,
//       revenueComparison,
//       salesComparison,
//       profitComparison,
//       roasComparison,
//       cpaComparison,
//       cpcComparison,
//       crComparison,
//       ctrComparison,
//       cpmComparison,
//     },
//   ];

//   console.log(data1);
//   console.log(spendComparison);
//   console.log(revenueComparison);
//   console.log();

//   const { tableData, isFetching } = table;

//   const [selectedDates, setSelectedDates] = useState([]);
//   const [selectedCompareDates, setSelectedCompareDates] = useState([]);
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const { date } = useSelector((state) => state.date);
//   const { comparedate } = useSelector((state) => state.date);
//   const dispatch = useDispatch();

//   const formatDate = (date) => {
//     return date ? date.toDateString().slice(4) : "";
//   };

//   const setCurrentDayRange = () => {
//     const today = new Date();
//     const selected = [formatDate(today), formatDate(today)];

//     setSelectedDates(selected);
//   };
//   const setCurrentCompareDayRange = () => {
//     const today = new Date();
//     const selected = [formatDate(today), formatDate(today)];
//     console.log(selected);

//     setSelectedCompareDates(selected);
//   };

//   useEffect(() => {
//     setCurrentDayRange();
//   }, []);
//   useEffect(() => {
//     setCurrentCompareDayRange();
//   }, []);

//   useEffect(() => {
//     const traffic = document.querySelector(".sc-eTNRI lnOUep");
//   }, []);

//   const openPopup = () => {
//     setPopupOpen(true);
//   };

//   const closePopup = () => {
//     setPopupOpen(false);
//   };

//   const updateSelectedDates = (dates) => {
//     console.log(dates);
//     setSelectedDates(dates);
//     closePopup();
//   };
//   const updateSelectedCompareDates = (dates) => {
//     console.log(dates);
//     setSelectedCompareDates(dates);
//     closePopup();
//   };

//   const cancelSelectedDates = () => {
//     setSelectedDates([]);
//   };

//   const handleTrafficChange = (event) => {
//     const selectedOption = event.target.value;
//     console.log(selectedOption);
//     dispatch(setTrafficSource(selectedOption));
//   };

//   const handleSelectChange = (event) => {
//     const selectedOption = event.target.value;
//     console.log(selectedOption);
//     dispatch(setAttribute(selectedOption));
//   };

//   return (
//     <>
//       <DataTable
//         className="custom-data-table1"
//         title={
//           <CustomTitle
//             // customDate={customDate}
//             selectedCompareDates={selectedCompareDates}
//             date={date}
//             updatedSelectedCompareDates={updateSelectedCompareDates}
//             selectedDates={selectedDates}
//             isPopupOpen={isPopupOpen}
//             openPopup={openPopup}
//             closePopup={closePopup}
//             updateSelectedDates={updateSelectedDates}
//             cancelSelectedDates={cancelSelectedDates}
//             setDate={setDate}
//             tableData={tableData}
//             comparedate={comparedate}
//           />
//         }
//         columns={columns1}
//         data={isFetching ? [] : data1}
//         pointerOnHover
//         responsive
//         subHeader
//         subHeaderComponent={
//           <CustomSubHeaderComponent
//             filterText={filterText}
//             setFilterText={setFilterText}
//             setFilteredData={setFilteredData}
//             trafficSource={trafficSource}
//             handleTrafficChange={handleTrafficChange}
//             attribute={attribute}
//             handleSelectChange={handleSelectChange}
//             tableData={tableData}
//             setSelectedAdList={setSelectedAdList}
//           />
//         }
//         subHeaderAlign="left"
//         subHeaderWrap
//         customStyles={customStyles1}
//         noDataComponent={isFetching && <LastReportLoader />}
//       />
//     </>
//   );
// };

// export default LastReport;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { fbLogo } from "../../assets";
import DataTable from "react-data-table-component";
import TrafficSourceCell from "./TrafficSourceCell";
import CustomTitle from "./CustomTitle";
import CustomSubHeaderComponent from "./CustomSubHeaderComponent";
import { transformToInternationalNumber, formatNumber } from "../../utils";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import "./styles.css";
import { LastReportLoader, Popup } from "../../components";
import { setCompareDate } from "../../redux/reducers/dateSlice";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const customStyles1 = {
  subHeader: {
    style: {
      backgroundColor: "inherit",
      color: "white",
      border: "none",
      padding: "16px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "10px",
    },
  },
  headRow: {
    style: {
      color: "#b3b703",
      border: "none",
      backgroundColor: "#1b1c1e",
    },
  },
  cells: {
    style: {
      backgroundColor: "#424448",
      color: "white",
    },
  },
};

const renderReportingCell = (row, fieldName, comparisonFieldName) => {
  let value = row[fieldName];
  const comparisonValue = row[comparisonFieldName];

  const displayValue = () => {
    if (fieldName === "Revenue" || fieldName === "Spend") {
      if (
        value !== -1 &&
        value != "-1" &&
        value !== null &&
        value !== undefined
      ) {
        const cleanedStr = value?.replace(/,/g, "");
        const num = parseFloat(cleanedStr);
        value = Math.round(num);
      }
    }

    return value == -1 ||
      value === "-1" ||
      value === null ||
      value === undefined
      ? "n/a"
      : transformToInternationalNumber(value);
  };

  const getColor = () => {
    // If it's CPAComparison, change the color logic
    if (
      comparisonFieldName === "CPAComparison" ||
      comparisonFieldName === "nCPAComparison"
    ) {
      return comparisonValue > 0 ? "red" : "#00E723";
    }
    // Default color logic
    return comparisonValue < 0 ? "red" : "#00E723";
  };

  return (
    <div className="reporting-cell">
      <span title={value}>{displayValue()}</span>
      {comparisonValue !== null && comparisonValue !== undefined && (
        <span
          title={comparisonValue}
          style={{
            fontSize: "11px",
            color:
              comparisonValue === "-" ||
                typeof comparisonValue !== "number" ||
                comparisonValue < 0
                ? "red"
                : "#00E723",
          }}
        >
          {comparisonValue === "-" ? (
            <span style={{ color: "red" }}>-</span>
          ) : comparisonValue < 0 ? (
            <>
              <BsArrowDown style={{ color: getColor() }} />
              <span
                style={{ color: getColor() }}
              >{`(${comparisonValue}%)`}</span>
            </>
          ) : (
            <>
              <BsArrowUp style={{ color: getColor() }} />
              <span
                style={{ color: getColor() }}
              >{`(${comparisonValue}%)`}</span>
            </>
          )}
        </span>
      )}
    </div>
  );
};

const columns1 = (columnConfigs) => {


  const nameColumn = (name, formula = undefined, index) => {
    if (formula) {
      let noSpaceId = name.replace(/[\s*%]/g, "") + index;

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            minWidth: name === "New Visits* %" && "110px",
          }}
        >
          <span>{name}</span>
          <IoInformationCircleOutline
            id={noSpaceId}
            size={15}
            style={{ cursor: "pointer" }}
          />
          <Tooltip
            anchorSelect={`#${noSpaceId}`}
            content={formula}
            place="top"
            positionStrategy="fixed"
            className="tooltip"
          />
        </div>
      );
    }
    return name;
  };

  return [
    {
      name: "TRAFFIC SOURCE",
      cell: (row) => <TrafficSourceCell row={row} />,
      minWidth: "320px",
      paddingLeft: "20px",
    },
    ...columnConfigs.map(({ name, field, formula }, index) => ({
      name: nameColumn(name, formula, index),
      selector: (row) => row[field],
      columnName: field,
      minWidth: name === "New Visits* %" && "110px",
      cell: (row) => renderReportingCell(row, field, `${field}Comparison`),
    })),
  ];
};

const LastReport = ({
  setFilterText,
  filterText,
  setFilteredData,
  setDate,
  table,
  attribute,
  setAttribute,
  trafficSource,
  setTrafficSource,
  setSelectedAdList,
  maindata,
  columnConfigs
}) => {
  const expandShow = maindata ? maindata[1]?.campaign.length : 0;
  const item =
    Array.isArray(maindata) && maindata.length > 0 ? maindata[0] : {};

  // Initialize comparison metrics
  let compMetrics = {};

  // Access comparison data
  const compItem =
    Array.isArray(maindata) && maindata.length > 1 ? maindata[1] : {};

  if (compItem) {
    if (Object.keys(compItem).length > 0) {
      Object.keys(compItem).forEach((key) => {
        compMetrics[key] = compItem[key] || 0;
      });
    }
  }

  // Comparison helper function
  const calculateComparison = (value, compValue) => {
    if (expandShow > 0 && compValue !== -1 && compValue !== 0) {
      const comparison = formatNumber(
        ((value - compValue) / Math.abs(compValue)) * 100
      );

      // return comparison === 0 ? null : comparison;
      return comparison === 0 ? null : parseFloat(comparison.toFixed(1));
    }
    return null;
  };

  const formattedValues = {};
  let comparesions = {};

  if (item) {
    const { campaign, ...otherItems } = item;

    Object.keys(otherItems).forEach((key) => {
      formattedValues[key] = transformToInternationalNumber(
        formatNumber(item[key])
      );
    });

    Object.keys(otherItems).forEach((key) => {
      comparesions[`${key}Comparison`] = calculateComparison(
        item[key],
        compMetrics[key]
      );
    });
  }

  // Create data1 with formatted values
  const data1 = [
    {
      src:
        trafficSource === "Google" ? (
          <FcGoogle style={{ width: "16px", height: "16px" }} />
        ) : trafficSource === "Facebook" ? (
          <img
            src={fbLogo}
            alt="fbook"
            style={{ width: "16px", height: "16px" }}
          />
        ) : (
          ""
        ),
      trafficSource:
        trafficSource === "Google" ? (
          "Google"
        ) : trafficSource === "Facebook" ? (
          "Facebook"
        ) : (
          <>
            {" "}
            <div style={{ width: "16px", height: "16px" }}></div>{" "}
            {/*empty jsx for when no logo */}
            {trafficSource}
          </>
        ),

      ...formattedValues,
      ...comparesions,
    },
  ];

  const { tableData, isFetching } = table;

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedCompareDates, setSelectedCompareDates] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { date } = useSelector((state) => state.date);
  const { comparedate } = useSelector((state) => state.date);
  const { isCompareFetching } = useSelector((state) => state.compareTable);
  const dispatch = useDispatch();

  const formatDate = (date) => {
    return date ? date.toDateString().slice(4) : "";
  };

  const setCurrentDayRange = () => {
    const today = new Date();
    const selected = [formatDate(today), formatDate(today)];
    setSelectedDates(selected);
  };

  // I have added this line
  const setCurrentCompareDayRange = () => {
    const today = new Date();
    const selected = [formatDate(today), formatDate(today)];
    setSelectedCompareDates(selected);
  };

  useEffect(() => {
    setCurrentDayRange();
  }, []);

  useEffect(() => {
    const traffic = document.querySelector(".sc-eTNRI lnOUep");
  }, []);

  // useEffect(() => {
  //   setCurrentCompareDayRange();
  // }, []);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const updateSelectedDates = (dates) => {
    setSelectedDates(dates);
    closePopup();
  };

  const updateSelectedCompareDates = (dates) => {
    // console.log(dates);
    setSelectedCompareDates(dates);
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDates([]);
  };

  const handleTrafficChange = (event) => {
    const selectedOption = event.target.value;
    dispatch(setTrafficSource(selectedOption));
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    dispatch(setAttribute(selectedOption));
  };

  return (
    <>
      <DataTable
        className="custom-data-table1"
        title={
          <CustomTitle
            //I added this
            selectedCompareDates={selectedCompareDates}
            date={date}
            //I added this
            updatedSelectedCompareDates={updateSelectedCompareDates}
            selectedDates={selectedDates}
            isPopupOpen={isPopupOpen}
            openPopup={openPopup}
            closePopup={closePopup}
            updateSelectedDates={updateSelectedDates}
            cancelSelectedDates={cancelSelectedDates}
            setDate={setDate}
            tableData={tableData}
            //I added this
            setSelectedCompareDates={setSelectedCompareDates}
            comparedate={comparedate}
          />
        }
        columns={columns1(columnConfigs)}
        data={isFetching || isCompareFetching ? [] : data1}
        pointerOnHover
        responsive
        subHeader
        subHeaderComponent={
          <CustomSubHeaderComponent
            filterText={filterText}
            setFilterText={setFilterText}
            setFilteredData={setFilteredData}
            trafficSource={trafficSource}
            handleTrafficChange={handleTrafficChange}
            attribute={attribute}
            handleSelectChange={handleSelectChange}
            tableData={tableData}
            setSelectedAdList={setSelectedAdList}
          />
        }
        subHeaderAlign="left"
        subHeaderWrap
        customStyles={customStyles1}
        noDataComponent={
          (isFetching || isCompareFetching) && <LastReportLoader />
        }
      />
    </>
  );
};

export default LastReport;
