import React, { useEffect, useState } from "react";
import "./styles.css";
import Metrix from "../../components/CreativeComp/Metrix";

import { setCreativesDate } from "../../redux/reducers/dateSlice";
import { useDispatch, useSelector } from "react-redux";
import Video from "../../components/Video/Video";
import CreativeCard from "../../components/CreativeComp/Creativecard";
import CreativeTable from "../../components/CreativeComp/CreativeTable";
import CreativePopup from "../../components/CreativeComp/CreativePopup";
import AdMetric from "../../components/CreativeComp/AdMetric";
import { creativeTableData } from "../../redux/reducers/creativeTableDataSlice";
import { CreativeTableLoader } from "../../components/loadingComponent/SkeletonLoader";
import { DropDownComp } from "../../components";
import { updateMainCards } from "../../redux/reducers/breakdownSlice";
import ProfileDropDown from "./../../components/profileDropDown/ProfileDropDown";

function Creative() {
  const [opentheVideo, setOpentheVideo] = useState(false);

  const dispatch = useDispatch();
  const { creativesdate } = useSelector((state) => state?.date);
  const { isFetching, creativesClick, source } = useSelector((state) => state?.creativeTableData);

  useEffect(() => {
    if (creativesdate) {
      dispatch(
        creativeTableData({
          startdate: creativesdate[0],
          enddate: creativesdate[1],
          source: source
        })
      );
    }
  }, [dispatch, creativesdate, source]);

  // useEffect(() => {
  //   dispatch(updateMainCards([]));
  // }, []);

  const [creativeCards, setCreativeCards] = useState(
    JSON.parse(localStorage.getItem("creativeCards"))
      ? JSON.parse(localStorage.getItem("creativeCards"))
      : []
  );

  useEffect(() => {
    localStorage.setItem(
      "creativeCards",
      JSON.stringify(creativeCards ? creativeCards : [])
    );
    localStorage.setItem(
      "creativeCards",
      JSON.stringify(creativeCards ? creativeCards : [])
    );
  }, [creativeCards]);

  // console.log(creativeCards);
  const [dummyMetricbutton, setDummyMetricButton] = useState(false);
  // console.log(dummyMetricbutton);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const formatDate = (date) => {
    return date ? date.toDateString().slice(4) : "";
  };
  const [creativeInsightData, setCreativeInsightData] = useState(null);
  // console.log(creativeInsightData, "creativeInsightData");
  const setCurrentDayRange = () => {
    const today = new Date();
    const selected = [formatDate(today), formatDate(today)];
    setSelectedDates(selected);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const updateSelectedDates = (dates) => {
    setSelectedDates(dates);
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDates([]);
  };

  useEffect(() => {
    setCurrentDayRange();
  }, []);


  const handleAdMetric = () => {
    setDummyMetricButton(true);
  };
  useEffect(() => {
    setDummyMetricButton(false);
  }, []);

  return (
    <div className="creative-container">
      <div className="creative-header-section" style={{ paddingRight: "60px" }}>
        <div className="client-heading">
          <h2 className="profile-heading">Creative Analysis</h2>
          {/* <div className="creative-drop-down-css"> */}
          <DropDownComp />
        </div>
        <ProfileDropDown />
      </div>
      <div className="creative-popup-container">
        {creativesClick && creativeInsightData && (
          <CreativePopup creativeInsightData={creativeInsightData} />
        )}
      </div>

      <div className="creative-body">
        {!dummyMetricbutton && (
          <>
            <div className="metrix-box">
              <Metrix
                openPopup={openPopup}
                closePopup={closePopup}
                creativesdate={creativesdate}
                selectedDates={selectedDates}
                isPopupOpen={isPopupOpen}
                updateSelectedDates={updateSelectedDates}
                cancelSelectedDates={cancelSelectedDates}
                setCreativesDate={setCreativesDate}
              />
            </div>
            <div className="creative-grid-container">
              <div className="creatives-grid">
                {creativeCards &&
                  creativeCards.length > 0 &&
                  creativeCards.map((card) => (
                    <CreativeCard
                      key={card.id}
                      card={card}
                      opentheVideo={opentheVideo}
                      setOpentheVideo={setOpentheVideo}
                      setCreativeInsightData={setCreativeInsightData}
                      creativeCards={creativeCards}
                    />
                  ))}
              </div>
            </div>
          </>
        )}

        <div className="table">
          {isFetching ? (
            <CreativeTableLoader />
          ) : (
            <CreativeTable
              // startdate={creativesdate[0]}
              // enddate={creativesdate[1]}
              setCreativeInsightData={setCreativeInsightData}
              setCreativeCards={setCreativeCards}
              creativeCards={creativeCards}
            />
          )}
        </div>
      </div>

      <Video
        opentheVideo={opentheVideo}
        setOpentheVideo={setOpentheVideo}
        creativeInsightData={creativeInsightData}
      />
    </div>
  );
}

export default Creative;
