import React, { useEffect, useState } from "react";
import { setMeta } from "../../services/api/integrationApis"; // API call to set Meta Ads
import { normalizePath } from "../../utils"; // Utility function for normalizing paths
import { useLocation, useNavigate } from "react-router-dom"; // Hooks for routing
import { toastSetter } from "../../components/ToastContainer"; // Toast notification utility
import { fetchMetaAccounts } from "../../redux/reducers/IntegrationSlice"; // Redux action to fetch Meta accounts
import { useDispatch } from "react-redux"; // Redux hook for dispatching actions

const MetaAds = ({
  metaCustomers, // List of Meta customers/accounts
  metaAccessToken, // Access token for Meta API
  setIsPopupOpen, // Function to control popup visibility
  setPopupChildren, // Function to set children of the popup
  setMadsaccnt, // Function to set Meta Ads account status
}) => {
  const navigate = useNavigate(); // Navigate hook for programmatic navigation
  const location = useLocation(); // Location hook to get current location

  const dispatch = useDispatch(); // Dispatch hook for Redux actions
  const [selectedOptions, setSelectedOptions] = useState([]); // State for selected Meta accounts

  // Function to handle selection of Meta accounts
  const handleOptionChange = (item) => {
    if (selectedOptions.includes(item)) {
      // If item is already selected, remove it
      setSelectedOptions(selectedOptions.filter((option) => option !== item));
    } else {
      // If not selected and less than 3 are selected, add it
      if (selectedOptions.length < 3) {
        setSelectedOptions([...selectedOptions, item]);
      } else {
        // Alert user if trying to select more than 3 accounts
        const infoMessage = "You can only select up to 3 Meta Ads accounts.";
        toastSetter("info", infoMessage);
      }
    }
  };

  // Function to handle form submission
  const formSubmitHandler = (options) => {
    setMeta({
      accessToken: metaAccessToken, // Pass access token
      expireon: null, // Expiry information (null in this case)
      accountinfo: options, // Selected accounts
    })
      .then((res) => {
        if (res.status && res.status === 200) {
          // Successful API response handling
          toastSetter("success", `Meta Ads Success`);
          dispatch(fetchMetaAccounts()).unwrap(); // Fetch updated Meta accounts
          dispatch(setMadsaccnt(true)); // Update account status to true
        }
      })
      .catch((error) => {
        // Error handling
        console.log("Login Error:", error);
        toastSetter("error", `Meta Ads Error`);
        dispatch(setMadsaccnt(false)); // Update account status to false
      })
      .finally(() => {
        // Cleanup after submission
        setIsPopupOpen(false); // Close the popup
        setPopupChildren(""); // Reset popup children
        normalizePath(location, navigate); // Normalize the path
      });
  };

  // Handle form submission event
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    formSubmitHandler(selectedOptions); // Call form submission handler with selected options
  };

  // Handle clicks on disabled button
  const handleDisabledButtonClick = () => {
    if (selectedOptions.length < 1) {
      // Alert if no accounts are selected
      const alertMessage = "Please select at least one Meta Ads account.";
      toastSetter("info", alertMessage);
    }
  };

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Meta</h2>
      <p className="integration-popup-p">Select up to 3 Meta Ads accounts</p>
      <form className="integration-popup-form">
        {/* Render a checkbox for each Meta customer */}
        {metaCustomers.map((item) => (
          <span style={{ width: "100%" }} key={item.id}>
            <div className="integration-popup-input input-radio">
              <input
                type="checkbox" // Checkbox input for multiple selections
                value={item.name}
                checked={selectedOptions.includes(item)} // Check if the option is selected
                onChange={() => handleOptionChange(item)} // Handle change event
                style={{ cursor: "pointer", marginRight: "10px" }}
              />
              <span>{item.name}</span> {/* Display account name */}
            </div>
          </span>
        ))}
        <button
          type="button"
          className={
            selectedOptions.length < 1
              ? "integration-popup-submit integration-popup-submit-disabled" // Disabled styling
              : "integration-popup-submit" // Active styling
          }
          onClick={
            selectedOptions.length < 1
              ? handleDisabledButtonClick // Show alert if disabled
              : handleFormSubmit // Submit the form if valid
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default MetaAds;
