import { toastSetter } from "../../components/ToastContainer";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const refreshLoggedInUser = catchAsync(
  async (workSpaceId, refreshToken) => {
    try {
      const response = await http.post(`/refresh`, {
        headers: {
          workspaceId: workSpaceId,
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      // console.log(response);
      if (response && response.status === 200) {
        setSession(response.data);
        return response;
      }
    } catch (error) {
      // console.log("Error refreshing token:", error);
      return null;
    }
  }
);

/**
 * *Set Session Fot User
 * @param {object} tokens
 */

const setSession = (data) => {
  // console.log(data, "SET SESSION");
  if (data) {
    console.log(data);
    data.isagency &&
      data.agency_id &&
      localStorage.setItem("t_agency_id", data.agency_id);
    data.user_id && localStorage.setItem("t_workSpaceId", data.user_id);
    data.access && localStorage.setItem("t_accessToken", data.access);
    data.tokens.access &&
      localStorage.setItem("t_accessToken", data.tokens.access);
    data.tokens.refresh &&
      localStorage.setItem("t_refreshToken", data.tokens.refresh);
  } else {
    localStorage.removeItem("t_workSpaceId");
    localStorage.removeItem("t_accessToken");
    localStorage.removeItem("t_refreshToken");
    localStorage.removeItem("t_agency_id");
  }
};

/**
 * *User login API.
 * @function ({{email: string, password: string}} values)
 * @returns {object}
 */

export const createUser = catchAsync(async (values) => {
  try {
    const response = await http.post(`/auth/create`, values);
    // console.log(response, "CREATE USER");
    if (response.status === 201) {
      // setSession(data);
      const successMessage =
        response && response.data.message
          ? response.data.message
          : "User Registered Successfully";
      toastSetter("success", successMessage);
    } else {
      const errorMessage = response
        ? response.data.message
        : "User Registration Failed";
      toastSetter("error", errorMessage);
    }
    return response;
  } catch (error) {
    const errorMessage =
      error && error.response.data.message
        ? error.response.data.message
        : "User Registration Failed";
    toastSetter("error", errorMessage);
    throw error; // Rethrow the error to be caught elsewhere if necessary
  }
});

export const userLogin = catchAsync(async (values) => {
  try {
    const response = await http.post(`/auth/login`, values);

    if (response.status === 200) {
      setSession(response.data);
      const successMessage =
        response && response.data.message
          ? response.data.message
          : "Login Successful";
      toastSetter("success", successMessage);
    } else {
      const errorMessage =
        response && response.data.message
          ? response.data.message
          : "Login Failed";
      toastSetter("error", errorMessage);
    }
    // console.log(response, "USER LOGIN");

    return response;
  } catch (error) {
    const errorMessage =
      error && error.response.data.message
        ? error.response.data.message
        : "Login Failed";
    toastSetter("error", errorMessage);
    // console.log("Error in userLogin:", error); // Log any errors that occur
    throw error; // Rethrow the error to be caught elsewhere if necessary
  }
});

export const forgotPassword = catchAsync(async (values) => {
  try {
    const response = await http.post(`/auth/forgetpassword`, values);
    // console.log(response, "FORGOT PASSWORD");

    if (response.status === 200) {
      const successMessage =
        response && response.data.message
          ? response.data.message
          : "Check Your Email";
      toastSetter("success", successMessage);
    } else {
      const errorMessage =
        response && response.data.message ? response.data.message : "Failed";
      toastSetter("error", errorMessage);
    }
    return response;
  } catch (error) {
    const errorMessage =
      error && error.response.data.message
        ? error.response.data.message
        : "Failed";
    toastSetter("error", errorMessage);
    // console.log("Error in userLogin:", error);
    throw error;
  }
});

export const resetPassword = catchAsync(async (values) => {
  try {
    const response = await http.post(`/auth/resetpassword`, values);
    // console.log(response, "FORGOT PASSWORD");

    if (response.status === 200) {
      const successMessage =
        response && response.data.message
          ? response.data.message
          : "Successfully Changed";
      toastSetter("success", successMessage);
    } else {
      const errorMessage =
        response && response.data.message ? response.data.message : "Failed";
      toastSetter("error", errorMessage);
    }
    return response;
  } catch (error) {
    const errorMessage =
      error && error.response.data.message
        ? error.response.data.message
        : "Failed";
    toastSetter("error", errorMessage);
    // console.log("Error in userLogin:", error);
    throw error;
  }
});

export const userLogout = () => {
  setSession(null);
  localStorage.clear();
  setTimeout(() => {
    window.location.replace("/sign-in");
  }, 500);
  toastSetter("success", "User Logged Out Successfully");
};
