import React, { useRef } from "react";
import { FiCopy } from "react-icons/fi"; // Importing icon for copy functionality
import { toastSetter } from "./../../components/ToastContainer"; // Function to show toast notifications
import { shortenURL } from "../../utils/shortenUrl"; // Utility function to shorten URLs
import { setWooCommerce } from "../../services/api/integrationApis"; // API function for WooCommerce integration
import { useFormik } from "formik"; // Importing Formik for form management
import * as Yup from "yup"; // Importing Yup for validation schema
import { shoIntegration } from "../../services/api/integrationApis"; // Import shoIntegration function

const WooCommerce = ({
  signatureKey,
  setIsPopupOpen,
  setPopupChildren,
  setInte,
}) => {
  // Retrieve workspace ID and access token from local storage
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const accessToken = localStorage.getItem("t_accessToken"); // Access token currently not used
  const textRef = useRef(null); // Ref for the URL text element
  const fullURL = `https://backend.trackocity.io/clientpayment/${workSpaceId}/woocommercewebhook`; // Constructing the full webhook URL

  // Function to copy the webhook URL to the clipboard
  const copyText = () => {
    const tempTextArea = document.createElement("textarea"); // Create a temporary textarea
    tempTextArea.value = fullURL; // Set its value to the full URL
    document.body.appendChild(tempTextArea); // Append it to the body
    tempTextArea.select(); // Select the text
    document.execCommand("copy"); // Execute the copy command
    document.body.removeChild(tempTextArea); // Remove the textarea from the document

    toastSetter("success", "Copied to clipboard"); // Show success notification
  };

  // Validation schema for the form fields
  const validationSchema = Yup.object().shape({
    wooClientSecret: Yup.string().required("Field is required"), // Client Secret is required
    wooSignature: Yup.string().required("Field is required"), // Signature Key is required
  });

  // Formik setup for managing form state
  const formik = useFormik({
    initialValues: {
      wooClientSecret: "", // Initial value for Client Secret
      wooSignature: signatureKey, // Initial value for Signature Key from props
    },
    validationSchema, // Apply the validation schema
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true); // Set submitting state to true

      // Submit the WooCommerce API details
      setWooCommerce({
        woocommerce_client_secret: values.wooClientSecret,
        woocommerce_signature: values.wooSignature,
      })
        .then((res) => {
          console.log("WooCommerce configuration submitted:", res);
          setInte((prev) => !prev);
          // // After successful WooCommerce setup, call shoIntegration to fetch updated connection status
          // shoIntegration() // Re-fetch the integration status
          //   .then((integrationStatus) => {
          //     // Update localStorage based on the integration status
          //     const wooConnected = integrationStatus.woocommerce;
          //     console.log(wooConnected);
          //     localStorage.setItem("Woo Commerce_connected", wooConnected);

          //     // Optionally, you can also update other app connections if needed
          //     console.log("Updated Integration Status:", integrationStatus);

          //     // Show success toast
          //   })
          //   .catch((error) => {
          //     console.error("Error fetching integration status:", error);
          //     toastSetter("error", "Error updating integration status");
          //   });
        })
        .catch((error) => {
          console.error("Error setting WooCommerce configuration:", error);
          toastSetter("error", "Error submitting WooCommerce configuration");
        })
        .finally(() => {
          setSubmitting(false); // Reset submitting state
          setIsPopupOpen(false); // Close the popup
          setPopupChildren(""); // Clear popup content
        });
    },
  });

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Woo Commerce</h2>
      <form className="integration-popup-form">
        {/* Client Secret Input */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Client Secret</label>
          <input
            id="wooClientSecret" // Unique ID for the input field
            name="wooClientSecret" // Name used by Formik to manage this field
            type="text"
            className="integration-popup-input"
            value={formik.values.wooClientSecret} // Controlled input value
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span>
        {/* Webhook URL Display with Copy Button */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Webhook URL</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="integration-popup-input"
          >
            <p ref={textRef} className="text-dec-none webhook-link">
              {shortenURL(fullURL, 60)}
            </p>
            <button
              type="button"
              className="copy-button"
              style={{ top: "0", left: "0" }}
              onClick={copyText} // Trigger copy function
              onMouseEnter={() => {
                textRef.current.style.textDecoration = "underline"; // Underline on hover
                textRef.current.setAttribute("data-tooltip", "Click to copy"); // Show tooltip on hover
              }}
              onMouseLeave={() => {
                textRef.current.style.textDecoration = "none"; // Remove underline when not hovering
                textRef.current.removeAttribute("data-tooltip"); // Hide tooltip
              }}
            >
              <span className="tooltip-text">Click to copy</span>
              <FiCopy scale={0.8} size={22} />
            </button>
          </div>
        </span>
        {/* Signature Key Display */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Signature Key</label>
          <input
            id="wooSignature" // Unique ID for the input field
            name="wooSignature" // Name used by Formik to manage this field
            type="text"
            className="integration-popup-input"
            value={formik.values.wooSignature} // Controlled input value
            readOnly // Read-only input
            // onChange={formik.handleChange} // Not used for read-only input
            // onBlur={formik.handleBlur} // Not used for read-only input
          />
        </span>
      </form>
      {/* Submit Button */}
      <button
        type="button"
        className="integration-popup-submit"
        disabled={formik.isSubmitting} // Disable button if form is submitting
        onClick={formik.handleSubmit} // Trigger Formik's submit handler
      >
        Submit
      </button>
    </div>
  );
};

export default WooCommerce;
