import { createSlice } from "@reduxjs/toolkit";

// Function to get date string for 'n' days ago
const getDateStringForNDaysAgo = (n) => {
  const date = new Date();
  date.setDate(date.getDate() - n);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

// Get today's date
const today = new Date().toLocaleDateString("en-US", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

// Get the date string for 6 days ago
const sixDaysAgo = getDateStringForNDaysAgo(6);

const initialState = {
  date: [sixDaysAgo, today],
  dashdate: [sixDaysAgo, today],
  popupdate: [sixDaysAgo, today],
  creativesdate: [sixDaysAgo, today],
  behaviourdate: [sixDaysAgo, today],

  
 
  comparedate: [],
};

const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate(state, action) {
      console.log(action.payload);
      state.date = action.payload;
    },
    setCompareDate(state, action) {
      state.comparedate = action.payload;
    },
    setPopupDate(state, action) {
      state.popupdate = action.payload;
    },
    setDashDate(state, action) {
      state.dashdate = action.payload;
    },
    setCreativesDate(state, action) {
      state.creativesdate = action.payload;
    },
    setBehaviourDate(state, action) {
      console.log(action.payload);
      state.behaviourdate = action.payload;
    },
   
  },
});

export const {
  setDate,
  setDashDate,
  setPopupDate,
  setCreativesDate,
  setBehaviourDate,
  setCompareDate,
} = dateSlice.actions;

export default dateSlice.reducer;
