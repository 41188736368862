import React, { useState, useEffect } from "react";
import "./styles.css";

import CodeTab from "./Codetab";
import AppsTab from "./AppsTab";
import { DropDownComp, Popup } from "../../components";
import VideoTutorialPopups from "./VideoTutorialPopups";
import Razorpay from "./Razorpay";
import Shopify from "./Shopify";
import WooCommerce from "./WooCommerce";
import GoogleAds from "./GoogleAds";
import MetaAds from "./MetaAds";
import Pabbly from "./Pabbly";
import Cashfree from "./Cashfree";

import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils";
import { fetchGoogleAdAccounts } from "../../redux/reducers/googleAdAccountsSlice";
import catchAsync from "../../utils/catchAsync";
import { normalizePath } from "../../utils";
import GoogleAdsFetched from "./GoogleAdsFetched";
import { setGadsaccnt } from "../../redux/reducers/gadsaccntSlice";
import { setMadsaccnt } from "../../redux/reducers/madsaccntSlice";
import { extractCustomerIDsAndRefreshToken } from ".";
import { fetchMetaAccounts } from "../../redux/reducers/IntegrationSlice";
import { setMeta } from "../../services/api/integrationApis";
import { useLocation, useNavigate } from "react-router-dom";
import MetaAdsFetched from "./MetaAdsFetched";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";

const Integration = () => {
  const [inte, setInte] = useState(false);
  // Hooks for managing state and navigation
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedTab") || "Apps"
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const [popupChildren, setPopupChildren] = useState(""); // State to manage content of the popup

  const [clearConnected, setClearConnected] = useState(false);
  const { currentClient } = useSelector((state) => state.agency);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  // Function to open popup with specific content
  const popupHandler = (content) => {
    setPopupChildren(content);
    setIsPopupOpen(true);
  };

  // Function to close popup and perform clean-up
  const closePopup = () => {
    // popupChildren === "meta-utm" && handleCopyScriptTour();
    setIsPopupOpen(false);
    setPopupChildren("");
    // localStorage.removeItem("gadsaccnt");
    // localStorage.removeItem("madsaccnt");
    // localStorage.removeItem("metaAccessToken");
    // localStorage.removeItem("metaCustomers");
    normalizePath(location, navigate);
  };

  const dispatch = useDispatch();

  const [signatureKey, setSignatureKey] = useState("");

  // Redux state selectors
  const { metaAccounts } = useSelector((state) => state.integration);
  console.log(metaAccounts);
  const { googleAdAccounts } = useSelector((state) => state.googleAdAccounts);
  const gadsaccnt = useSelector((state) => state.gadsaccnt);
  const madsaccnt = useSelector((state) => state.madsaccnt);

  // State for Google and Meta account data
  const [googleCustomers, setGoogleCustomers] = useState([]);
  const [googleRefreshToken, setGoogleRefreshToken] = useState(null);
  const [metaAccessToken, setMetaAccessToken] = useState(
    () => getLocalStorageItem("metaAccessToken", "") || ""
  );
  const [metaCustomers, setMetaCustomers] = useState(() =>
    getLocalStorageItem("metaCustomers", [] || [])
  );

  // Fetch Google Ad accounts when Google account state changes
  useEffect(() => {
    dispatch(fetchGoogleAdAccounts()).unwrap();
  }, [gadsaccnt, currentClient]);

  // Fetch Meta accounts when Meta account state changes
  useEffect(() => {
    dispatch(fetchMetaAccounts()).unwrap();
  }, [madsaccnt, currentClient]);

  useEffect(() => {
    const generateRandomString = () => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 20; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    setSignatureKey(generateRandomString());
  }, [popupChildren, isPopupOpen]);

  // Effect to handle customer IDs and refresh token extraction from URL
  useEffect(() => {
    const { customerIDs, refreshToken } = extractCustomerIDsAndRefreshToken(
      window.location.href
    );

    if (customerIDs.length > 0 && refreshToken) {
      setGoogleCustomers(customerIDs);
      setGoogleRefreshToken(refreshToken);
      popupHandler("Google Ads");
    } else {
      console.log("No accessible customers found");
    }
  }, []);

  // Link Google Ads account
  const onLinkAdsAccount = (g_id) => {
    const id = g_id || null;
    window.location.href = `${process.env.REACT_APP_BASE_URL}/google/authorize/${id}`;
  };

  // List accessible customers and handle any errors
  const listAccessibleCustomers = catchAsync(async (type, id) => {
    if (!type) return;

    localStorage.setItem("t_systemid", id);
    localStorage.setItem("t_popupInputType", type);

    try {
      onLinkAdsAccount(id);
    } catch (error) {
      console.log("Error fetching accessible customers:", error);
    }
  });

  // Connect Google Ad accounts
  const connectGoogleAdAccounts = async () => {
    try {
      if (!googleAdAccounts || googleAdAccounts.accounts.length === 0) {
        await listAccessibleCustomers("checkbox");
      } else {
        dispatch(setGadsaccnt(true));
      }
    } catch (error) {
      console.log("Error in checkGoogleAdAccounts:", error);
      dispatch(setGadsaccnt(false));
    }
  };

  // Connect Meta Ad accounts
  const connectMetaAdAccounts = () => {
    try {
      if (!metaAccounts || metaAccounts.accounts.length <= 0) {
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?app_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&config_id=${process.env.REACT_APP_CONFIG_ID}&response_type=code&override_default_response_type=True`;
      } else if (metaAccounts && metaAccounts.accounts.length > 0) {
        dispatch(setMadsaccnt(true));
      }
    } catch (error) {
      console.log("Error in connectMetaAdAccounts:", error);
    }
  };

  // Handle Facebook callback for token exchange
  const handleFacebookCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const shortLivedToken = params.get("code");

    if (shortLivedToken) {
      try {
        const response = await fetch(
          `https://graph.facebook.com/v19.0/oauth/access_token?client_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&code=${shortLivedToken}`
        );
        const data = await response.json();
        const longLivedToken = data.access_token;

        const adAccountsUrl = `https://graph.facebook.com/v19.0/me/adaccounts?access_token=${longLivedToken}&fields=id,name`;
        const res = await fetch(adAccountsUrl);
        const metadata = await res.json();

        console.log("Long-lived token:", longLivedToken);
        console.log("Ad accounts metadata:", metadata);

        setMetaAccessToken(longLivedToken);
        setMetaCustomers(metadata.data);

        if (metadata.data.length > 3) {
          popupHandler("Meta Ads");
        } else {
          autoFormSubmitHandler(longLivedToken, metadata.data);
        }
      } catch (error) {
        console.log("Error fetching ad accounts:", error.message);
      }
    }
  };

  // Automatically submit form with retrieved Meta data
  const autoFormSubmitHandler = (metaAccessToken, metaCustomers) => {
    console.log("autoFormSubmitHandler", metaAccessToken, metaCustomers);
    setMeta({
      accessToken: metaAccessToken,
      expireon: null,
      accountinfo: metaCustomers,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log("Login Error:", error);
      })
      .finally(() => {
        setIsPopupOpen(false);
        setPopupChildren("");
        normalizePath(location, navigate);
      });
  };

  useEffect(() => {
    handleFacebookCallback();
  }, []);

  // Video tutorial URLs mapping
  const videoUrls = {
    "Code Section Video": "https://www.youtube.com/embed/RvPWNQmNurg?rel=0",
    "Meta Video Tutorial":
      "https://www.youtube.com/embed/sUEgGsCCQ-g?si=SaXZHyKncEE3cNYf?rel=0",
    "Google Video Tutorial":
      "https://www.youtube.com/embed/ror0KKwhISw?si=LVhuWRTmyvEPVgGX?rel=0",
    "Razorpay Video Tutorial":
      "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",
    /*"Cashfree Video Tutorial":
      "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",*/
    "google-utm":
      "https://www.youtube.com/embed/DAhVIbWNXlE?si=RtEqQWHBo8pAtrPw?rel=0",
    "meta-utm":
      "https://www.youtube.com/embed/3uWtNQ1CYcQ?si=YM-zqbTMjlAHJMxt?rel=0",
    // Add more mappings as needed
  };

  const currentVideoUrl = videoUrls[popupChildren]; // Current video URL based on popup content

  // Component mapping for dynamic rendering
  const componentMap = {
    "Razorpay Integration": Razorpay,
    "Cashfree Integration": Cashfree,
    "Shopify Integration": Shopify,
    "WooCommerce Integration": WooCommerce,
    "Google Ads": GoogleAds,
    "Meta Ads": MetaAds,
    "Pabbly Integration": Pabbly,
  };

  const componentProps = {
    "Razorpay Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Cashfree Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Shopify Integration": { setIsPopupOpen, setPopupChildren, setInte },
    "WooCommerce Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Google Ads": {
      setIsPopupOpen,
      setPopupChildren,
      googleCustomers,
      googleRefreshToken,
      setGadsaccnt,
      setInte,
    },
    "Meta Ads": {
      setIsPopupOpen,
      setPopupChildren,
      metaCustomers,
      metaAccessToken,
      setMadsaccnt,
      setInte,
    },
    "Pabbly Integration": { setIsPopupOpen, setPopupChildren, setInte },
  };

  const CurrentComponent = componentMap[popupChildren];
  const props = componentProps[popupChildren];

  return (
    <>
      <div className="integration-container">
        <div
          className="integration-header-section"
          style={{ paddingRight: "60px" }}
        >
          <div className="client-heading">
            <span className="integration-header-nav-section">
              <button
                className={`integration-header-nav-button ${
                  selectedTab === "Apps" ? "active-integration-tab" : ""
                }`}
                onClick={() => handleTabClick("Apps")}
              >
                Apps
              </button>
              <button disabled className="button-separator"></button>
              <button
                className={`integration-header-nav-button integration-header-nav-button-utm ${
                  selectedTab === "Code" ? "active-integration-tab" : ""
                }`}
                onClick={() => handleTabClick("Code")}
              >
                Code
              </button>
            </span>
            <DropDownComp />
          </div>
          <ProfileDropDown />
        </div>
        <div
          className="integration-body-section"
          style={{ overflowY: "scroll" }}
        >
          {selectedTab === "Apps" && !gadsaccnt && !madsaccnt && (
            <AppsTab
              popupHandler={popupHandler}
              connectGoogleAdAccounts={connectGoogleAdAccounts}
              connectMetaAdAccounts={connectMetaAdAccounts}
              inte={inte}
            />
          )}
          {selectedTab === "Apps" && gadsaccnt && !madsaccnt && (
            <GoogleAdsFetched
              setGadsaccnt={setGadsaccnt}
              googleAdAccounts={googleAdAccounts}
              listAccessibleCustomers={listAccessibleCustomers}
              popupHandler={popupHandler}
            />
          )}
          {selectedTab === "Apps" && !gadsaccnt && madsaccnt && (
            <MetaAdsFetched
              setMadsaccnt={setMadsaccnt}
              metaAccounts={metaAccounts}
              popupHandler={popupHandler}
            />
          )}
          {selectedTab === "Code" && <CodeTab popupHandler={popupHandler} />}
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        {CurrentComponent && <CurrentComponent {...props} />}
        {currentVideoUrl && (
          <VideoTutorialPopups currentVideoUrl={currentVideoUrl} />
        )}
      </Popup>
    </>
  );
};

export default Integration;
