// import React, { useEffect, useRef, useState } from "react";
// import { AreaChart } from "../../components";

// import "./styles.css";

// import ReportDetails from "./ReportDetails";
// import LastReport from "./LastReport";
// import { fetchTableData } from "../../redux/reducers/tableDataSlice";

// import { useDispatch, useSelector } from "react-redux";
// import { setDate } from "../../redux/reducers/dateSlice";
// import { fetchGraphData } from "../../redux/reducers/graphDataSlice";
// import { setAttribute } from "../../redux/reducers/attributeSlice";
// import { setTrafficSource } from "../../redux/reducers/trafficSourceSlice";
// import { fetchTableDataCompare } from "../../redux/reducers/tableCompareDataSlice";

// const ReportingPage = () => {
//   const table = useSelector((state) => state.table);
//   const compareTable = useSelector((state) => state.compareTable);
//   const { tableData, isError, error, isFetching } = table;
//   const { tableCompareData } = compareTable;
//   const graphData = useSelector((state) => state.graphData);

//   const { attribute } = useSelector((state) => state.attribute);

//   const { trafficSource } = useSelector((state) => state.trafficSource);

//   const { date } = useSelector((state) => state.date);

//   const { comparedate } = useSelector((state) => state.date);
//   console.log(comparedate);
//   const dispatch = useDispatch();

//   const [filterText, setFilterText] = useState("");
//   const [newFilterData, setnewFilteredData] = useState(null);
//   console.log("newFilterData", newFilterData);
//   const [filteredData, setFilteredData] = useState(tableCompareData);
//   // const [toggle, setToggle] = useState(false);
//   const [selectedAdList, setSelectedAdList] = useState("");

//   useEffect(() => {
//     dispatch(
//       fetchTableData({
//         startdate: date[0],
//         enddate: date[1],
//         attribute: attribute,
//         trafficSource: trafficSource,
//       })
//     );
//   }, [attribute, date, trafficSource, dispatch]);
//   useEffect(() => {
//     if (comparedate && comparedate.length === 2 && attribute && trafficSource) {
//       dispatch(
//         fetchTableDataCompare({
//           startdate: comparedate[0],
//           enddate: comparedate[1],
//           attribute: attribute,
//           trafficSource: trafficSource,
//         })
//       );
//     }
//   }, [attribute, comparedate, trafficSource, dispatch]);

//   useEffect(() => {
//     // Create an array with both objects
//     const combinedData = [];

//     if (
//       typeof tableData === "object" &&
//       tableData !== null &&
//       Object.keys(tableData).length > 0
//     ) {
//       combinedData.push(tableData);
//     }

//     if (
//       typeof tableCompareData === "object" &&
//       tableCompareData !== null &&
//       Object.keys(tableCompareData).length > 0
//     ) {
//       combinedData.push(tableCompareData);
//     }

//     setnewFilteredData(combinedData); // Update filteredData with the combined data
//   }, [tableData, tableCompareData]);

//   useEffect(() => {
//     dispatch(
//       fetchGraphData({
//         startdate: date[0],
//         enddate: date[1],
//       })
//     );
//   }, [date, dispatch]);

//   useEffect(() => {
//     const table1 = document.querySelector(".custom-data-table1 ");
//     const table2 = document.querySelector(".custom-data-table2");

//     const syncScroll = (source, target) => {
//       if (target) {
//         target.scrollLeft = source.scrollLeft;
//       }
//     };

//     const handleScroll1 = () => syncScroll(table1, table2);
//     const handleScroll2 = () => syncScroll(table2, table1);

//     if (table1 && table2) {
//       table1.addEventListener("scroll", handleScroll1);
//       table2.addEventListener("scroll", handleScroll2);
//     }

//     return () => {
//       if (table1 && table2) {
//         table1.removeEventListener("scroll", handleScroll1);
//         table2.removeEventListener("scroll", handleScroll2);
//       }
//     };
//   }, []);

//   return (
//     <div className="profile-container">
//       <div className="profile-header-section">
//         <h2 className="profile-heading">Reporting</h2>
//       </div>
//       <div className="profile-body-section">
//         <div className="graph-card">
//           <AreaChart graphData={graphData} />
//           <div className="report-head">
//             <div className="sales">
//               <p className="yellow"></p>
//               <p>Sales</p>
//             </div>
//             <div className="revenue">
//               <p className="orange"></p>
//               <p>Revenue</p>
//             </div>
//           </div>
//           {/* <button className="configure-button">Show Configure Report</button> */}
//         </div>
//         <div
//           className="report-table-card"
//           // REPORT TABLE HERE
//         >
//           <LastReport
//             setFilterText={setFilterText}
//             filterText={filterText}
//             setFilteredData={setFilteredData}
//             table={table}
//             setDate={setDate}
//             attribute={attribute}
//             setAttribute={setAttribute}
//             trafficSource={trafficSource}
//             setTrafficSource={setTrafficSource}
//             setSelectedAdList={setSelectedAdList}
//             maindata={newFilterData}
//             // toggle={toggle}
//             // setToggle={setToggle}
//           />

//           <ReportDetails
//             className="main-child"
//             filterText={filterText}
//             // filteredData={filteredData}
//             filteredData={newFilterData}
//             table={table}
//             trafficSource={trafficSource}
//             selectedAdList={selectedAdList}
//             // toggle={toggle}
//           />
//           {/*<p style={{color:"white"}}><sup>*</sup> &nbsp; Field calculated on T+1 Day</p>*/}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReportingPage;

import React, { useEffect, useState } from "react";
import { AreaChart, DatePicker, DropDownComp } from "../../components";

import "./styles.css";

import ReportDetails from "./ReportDetails";
import LastReport from "./LastReport";
import { fetchTableData } from "../../redux/reducers/tableDataSlice";

import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../redux/reducers/dateSlice";
import { fetchGraphData } from "../../redux/reducers/graphDataSlice";
import { setAttribute } from "../../redux/reducers/attributeSlice";
import {
  fetchTrafficSources,
  setTrafficSource,
} from "../../redux/reducers/trafficSourceSlice";
import { fetchTableDataCompare } from "../../redux/reducers/tableCompareDataSlice";
import useFilterData from "../../hooks/useFilterData";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";

// All Tables columns
const columnConfigs = [
  { name: "Spend", field: "Spend" },
  { name: "Revenue", field: "Revenue" },
  { name: "ReportedRev", field: "Reported Rev" },
  { name: "Sales", field: "Sales" },
  { name: "ReportedSale", field: "Reported Sale" },
  { name: "ROAS", field: "ROAS" },
  { name: "ReportedROAS", field: "Reported ROAS" },
  { name: "CPA", field: "CPA" },
  { name: "ReportedCPA", field: "Reported CPA" },
  { name: "AOV", field: "AOV" },
  { name: "CPM", field: "CPM" },
  { name: "CPC", field: "CPC" },
  { name: "CTR %", field: "CTR %" },
  { name: "CR %", field: "CR %" },
  { name: "nRevenue", field: "nRevenue", formula: "New Customer Revenue" },
  { name: "nSales", field: "nSales", formula: "New Customer Sales" },
  { name: "nROAS", field: "nROAS", formula: "New Customer ROAS" },
  { name: "nAOV", field: "nAOV", formula: "New Customer AOV" },
  { name: "nCPA*", field: "nCPA", formula: "New Customer CPA" },
  { name: "nCPC*", field: "nCPC", formula: "New Customer CPC" },
  { name: "nCR % *", field: "nCR %", formula: "New Customer CR" },
  { name: "eCPNV*", field: "eCPNV", formula: "Effective Cost Per New Visit" },
  {
    name: "New Visits*",
    field: "New Visits",
    formula: "New Website Visitor",
  },
  {
    name: "New Visits* %",
    field: "New Visits %",
    formula: "New Website Visitor %",
  },
  { name: "Profit", field: "Profit" },
  { name: "Gross Margin %", field: "Gross Margin %" },
  { name: "Gross Profit", field: "Gross Profit" },
];

const ReportingPage = () => {
  const table = useSelector((state) => state.table);
  const compareTable = useSelector((state) => state.compareTable);
  const { trafficSource } = useSelector((state) => state.trafficSource);

  const { tableData } = table;

  const { tableCompareData } = compareTable;
  const graphData = useSelector((state) => state.graphData);

  const { attribute } = useSelector((state) => state.attribute);

  const { date } = useSelector((state) => state.date);

  const { comparedate } = useSelector((state) => state.date);

  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [newFilterData, setnewFilteredData] = useState(null);

  const { filterData } = useFilterData();

  // const [toggle, setToggle] = useState(false);
  const [selectedAdList, setSelectedAdList] = useState("");

  useEffect(() => {
    dispatch(
      fetchTableData({
        startdate: date[0],
        enddate: date[1],
        attribute: attribute,
        trafficSource: trafficSource,
      })
    );
  }, [attribute, date, trafficSource, dispatch]);

  useEffect(() => {
    dispatch(fetchTrafficSources());
  }, [fetchTrafficSources]);

  useEffect(() => {
    if (comparedate && comparedate.length === 2 && attribute && trafficSource) {
      dispatch(
        fetchTableDataCompare({
          startdate: comparedate[0],
          enddate: comparedate[1],
          attribute: attribute,
          trafficSource: trafficSource,
        })
      );
    }
  }, [attribute, comparedate, trafficSource, dispatch]);

  useEffect(() => {
    // Create an array with both objects
    const combinedData = [];

    if (
      typeof tableData === "object" &&
      tableData !== null &&
      Object.keys(tableData).length > 0
    ) {
      let finalTableData = tableData;
      if (
        tableData &&
        tableData?.campaign?.length !== 0 &&
        filterText.trim() !== ""
      ) {
        finalTableData = filterData(selectedAdList, filterText.trim());
      }
      combinedData.push(finalTableData);
      // combinedData.push(tableData);
    }

    if (
      typeof tableCompareData === "object" &&
      tableCompareData !== null &&
      Object.keys(tableCompareData).length > 0
    ) {
      combinedData.push(tableCompareData);
    }

    setnewFilteredData(combinedData); // Update filteredData with the combined data
  }, [tableData, tableCompareData]);

  useEffect(() => {
    dispatch(
      fetchGraphData({
        startdate: date[0],
        enddate: date[1],
      })
    );
  }, [date, dispatch]);

  useEffect(() => {
    const table1 = document.querySelector(".custom-data-table1 ");
    const table2 = document.querySelector(".custom-data-table2");

    const syncScroll = (source, target) => {
      if (target) {
        target.scrollLeft = source.scrollLeft;
      }
    };

    const handleScroll1 = () => syncScroll(table1, table2);
    const handleScroll2 = () => syncScroll(table2, table1);

    if (table1 && table2) {
      table1.addEventListener("scroll", handleScroll1);
      table2.addEventListener("scroll", handleScroll2);
    }

    return () => {
      if (table1 && table2) {
        table1.removeEventListener("scroll", handleScroll1);
        table2.removeEventListener("scroll", handleScroll2);
      }
    };
  }, []);

  useEffect(() => {
    let maxWidth = 0;

    // // Get the maximum width from both custom-data-table1 and custom-data-table2
    // const tables = document.querySelectorAll(
    //   ".custom-data-table1 > div >.rdt_Table, .custom-data-table2 > div >.rdt_Table,.custom-data-table2.sub-child-table ,.custom-data-table2.child-table"
    // );
    const tables = document.querySelectorAll(
      ".custom-data-table1 > div > .rdt_Table, " + // Selects `.rdt_Table` within `.custom-data-table1`
        ".custom-data-table2 > div > .rdt_Table, " + // Selects `.rdt_Table` within `.custom-data-table2`
        ".custom-data-table2.sub-child-table, " + // Selects `.custom-data-table2` with class `sub-child-table`
        ".custom-data-table2.child-table" // Selects `.custom-data-table2` with class `child-table`
    );

    tables.forEach((item) => {
      const itemWidth = item.offsetWidth;
      if (itemWidth > maxWidth) {
        maxWidth = itemWidth;
      }
    });

    // Apply the maximum width to custom-data-table1 elements
    document
      .querySelectorAll(
        ".custom-data-table1 > div >.rdt_Table, .custom-data-table2 > div >.rdt_Table,.custom-data-table2.sub-child-table ,.custom-data-table2.child-table"
      )
      .forEach((item) => {
        item.style.width = maxWidth + 40 + "px";
      });
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header-section" style={{ paddingRight: "60px" }}>
        <div className="client-heading">
          <h2 className="profile-heading">Reporting</h2>
          <DropDownComp />
        </div>
        <ProfileDropDown />
      </div>
      <div className="profile-body-section">
        <div className="graph-card">
          <AreaChart graphData={graphData} />
          {/* <div className="report-head">
            <div className="sales">
              <p className="yellow"></p>
              <p>Sales</p>
            </div>
            <div className="revenue">
              <p className="orange"></p>
              <p>Revenue</p>
            </div>
          </div> */}
          {/* <button className="configure-button">Show Configure Report</button> */}
        </div>
        <div
          className="report-table-card"
          // REPORT TABLE HERE
        >
          <LastReport
            columnConfigs={columnConfigs}
            setFilterText={setFilterText}
            filterText={filterText}
            setFilteredData={setnewFilteredData}
            table={table}
            d
            setDate={setDate}
            attribute={attribute}
            setAttribute={setAttribute}
            trafficSource={trafficSource}
            setTrafficSource={setTrafficSource}
            setSelectedAdList={setSelectedAdList}
            // this one i have added
            maindata={newFilterData}
            // toggle={toggle}
            // setToggle={setToggle}
          />

          <ReportDetails
            columnConfigs={columnConfigs}
            className="main-child"
            filterText={filterText}
            //  I removed this because I have different data to pass and added a new filtered Data
            // filteredData={filteredData}
            filteredData={newFilterData}
            table={table}
            trafficSource={trafficSource}
            selectedAdList={selectedAdList}
            // toggle={toggle}
          />
          <p style={{ color: "white", fontSize: "0.8rem" }}>
            <sup>*</sup> &nbsp; Field calculated on T+1 Day
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportingPage;
