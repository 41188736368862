import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";

import "./styles.css"; // Import CSS for additional styling
import { useDispatch, useSelector } from "react-redux";
import { px } from "framer-motion";
import { toastSetter } from "../../components/ToastContainer";
import { setCreativeState } from "../../redux/reducers/creativeTableDataSlice";

import { useNavigate } from "react-router-dom";
import {
  setbreakdownIds,
  setbreakdownThumbnail,
} from "../../redux/reducers/breakdownSlice";
import { fallBackImg } from "../../assets";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import { transformToInternationalNumber } from "../../utils";
import CreativeSearch from "./CreativeSearch";
import useFilterDataCreative from "../../hooks/useFilterDataCreative";

const CreativeTable = ({
  creativeCards,
  setCreativeInsightData,
  setCreativeCards,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterCreativeText, setFilterCreativeText] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [selectedCardz, setSelectedCardz] = useState([]);


  const { tableData, isFetching, source } = useSelector(
    (state) => state?.creativeTableData
  );

  const handleSelectChange = (e) => {
    setFilterType(e);
  };

  const { filteredData } = useFilterDataCreative();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("spend");
  const [filteredCreativeData, setFilteredCreativeData] = useState("");



  const data =
    filteredCreativeData &&
    filteredCreativeData.length > 0 &&
    filteredCreativeData.map((obj) => ({
      id: obj?.thumbnail_id,
      creative: obj?.creativename,
      ...obj,
    }));

  const sortedData = data
    ? data?.sort((a, b) => {
      if (typeof a[sortBy] === "string") {
        return sortDirection === "asc"
          ? a[sortBy].localeCompare(b[sortBy])
          : b[sortBy].localeCompare(a[sortBy]);
      } else {
        return sortDirection === "asc"
          ? a[sortBy] - b[sortBy]
          : b[sortBy] - a[sortBy];
      }
    })
    : [];

  // Use sortedData where needed, for example in rendering or passing to DataTable


  const netResultValues = {
    id: "netResultValues",
    creative: "Net Result",
    revenue: tableData?.revenue,
    spend: tableData?.spend,
    click: tableData?.click,
    roas: tableData?.roas,
    cpa: tableData?.cpa,
    cpm: tableData?.cpm,
    cpc: tableData?.cpc,
    ctr: tableData?.ctr,
    cr: tableData?.cr,
    aov: tableData?.aov,
    engage_rate: tableData?.engage_rate,
    hookrate: tableData?.hookrate,
    completion_rate: tableData?.completion_rate,
    sales: tableData?.sales,
    cpnv: tableData?.cpnv,
  };

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        background: "rgba(180, 180, 180, 0.2)",
        color: "white",
        fontWeight: "bold",
        color: "#FFF",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
      },
    },
    cells: {
      style: {
        backgroundColor: "#1B1C1E",
        color: "white",
        fontFamily: "Montserrat",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1B1C1E",
        color: "white",
      },
    },
  };
  const getDefaultSelectedRows = (data) => {

    // Ensure data is an array and not undefined
    if (!Array.isArray(data) || data?.length === 0) {
      return [];
    }

    // Sort the data array by 'spend' in descending order
    const sortedData = [...data].sort((a, b) => b.spend - a.spend);

    // Filter out the top items based on the sorted order (up to top 4)
    const topItems = sortedData.slice(0, 4);

    return topItems.map((item) => item.thumbnail_id);
  };

  let defaultRows = JSON.parse(localStorage.getItem("selectedRows"));
  const [selectedRows, setSelectedRows] = useState(
    defaultRows && Array.isArray(defaultRows) && defaultRows?.length > 0
      ? defaultRows
      : getDefaultSelectedRows(data || [])
  );

  // useEffect(() => {
  //   console.log(tableData?.creative.length, filteredCreativeData.length);

  //   if (tableData?.creative.length === data.length) {
  //     // Get selected rows based on the current data
  //     // const newSelectedRows = getDefaultSelectedRows(data);
  //     setSelectedRows(getDefaultSelectedRows(data));
  //     console.log(getDefaultSelectedRows(data));
  //     // setSelectedRows(newSelectedRows);
  //   }
  // }, [filteredCreativeData.length, data.length]);

  // useEffect(() => {
  //   console.log(selectedRows);
  // }, [selectedRows]);

  useEffect(() => {
    localStorage.setItem("selectedRows", JSON.stringify(selectedRows));
  }, [selectedRows, tableData]);

  const hasRun = useRef(false); // Ref to track if the effect has already run
  useEffect(() => {
    if (!hasRun.current && data) {

      const setCards = async () => {
        const requiredData = await data; // Wait for the data to be available
        setSelectedRows(getDefaultSelectedRows(requiredData)); // Set selected rows
      };

      setCards(); // Call the async function

      hasRun.current = true; // Mark the effect as having run
    }
  }, [data]);

  // useEffect(() => {
  //   if (data && data.length === tableData?.creative) {
  //     const setCards = async () => {
  //       const requiredData = await data; // Wait for the data to be available
  //       setSelectedRows(getDefaultSelectedRows(requiredData)); // Set selected rows
  //     };

  //     setCards(); // Call the async function
  //   }
  // }, [data]);
  const handleClick = (row) => {
    dispatch(setCreativeState(true));
    setCreativeInsightData(row);
  };

  const handleSelectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
      return;
    }
    let selectAllRow = data
      .filter((item, index) => {
        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 8;
        return index >= startIndex && index < endIndex;
      })
      .map((item) => item.id);
    setSelectedRows(selectAllRow);
  };

  const handleChange = (rowid) => {
    if (selectedRows.includes(rowid)) {
      setSelectedRows((rowIds) => rowIds.filter((id) => id !== rowid));
      return;
    }
    if (selectedRows?.length >= 8) {
      toastSetter("error", "Oops! You can only select a maximum of 8 rows.");
      return;
    } else {
      setSelectedRows((prevIds) => [...prevIds, rowid]);
    }
  };

  const handleBreakdown = (row) => {

    dispatch(setbreakdownIds(row.creativeid));
    dispatch(setbreakdownThumbnail(row.thumbnail));

    navigate(`/ad-breakdown`);
  };

  const handleImageError = (event) => {
    event.target.src = fallBackImg; // Replace with your fallback image path
  };

  let creativeNameHandler = (creativeName) =>
    creativeName?.length > 38
      ? creativeName.slice(0, 38) + "...."
      : creativeName;

  const roundupValues = (value) => {
    if (typeof value !== "number" || isNaN(value)) {
      return 0; // Handle invalid values
    }
    if (value === 0) {
      return 0;
    }

    // Return 2 decimal places if less than 100, otherwise remove decimals
    return value < 100 ? Number(value.toFixed(2)) : Math.round(value);
  };

  let columns = [
    {
      name: (
        <input
          type="checkbox"
          className="head-checkbox custom-checkbox"
          checked={selectedRows && selectedRows?.length > 0}
          onChange={handleSelectAll}
        />
      ),
      cell: (row) =>
        row.selectable !== false && (
          <input
            className="custom-checkbox"
            type="checkbox"
            checked={selectedRows?.includes(row.id)}
            onChange={() => handleChange(row.id)}
          />
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: `${selectedRows?.length
        } ads selected`,
      selector: (row) => {
        return (
          <div
            className="first-col-content"
            style={{ outline: "none", display: "flex" }}
          >
            {row.creative !== "Net Result" && (
              <img
                src={row.thumbnail}
                style={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "0.6rem",
                }}
                alt=""
                onError={handleImageError}
              />
            )}
            <div>
              <p
                className="table-cols-headings"
                style={{
                  marginLeft: row.creative === "Net Result" && "2rem",
                  fontWeight: row.creative === "Net Result" ? "500" : "400",
                }}
              >
                {creativeNameHandler(row.creativename)}
              </p>
              {row.creative !== "Net Result" && (
                <div className="table-cols-heading-options">
                  {source === 'facebook' && <span>{row?.adcount} ads  &nbsp;</span>}
                  <div className="table-cell-content">
                    {source === 'facebook' && <>| <span onClick={() => handleBreakdown(row)}>
                      Ad breakdown {" "}
                    </span>
                      | </>}{" "}
                    <span onClick={() => handleClick(row)}>
                      Creative insights
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      },
      columnName: "creative",
      sortable: true,
    },
    {
      name: <p>Revenue</p>,
      selector: (row) => Math.round(row?.revenue),
      sortable: true,
      columnName: "revenue",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Math.round(row?.revenue))}
        </span>
      ),
      style: {
        borderLeft: "none",
      },
    },
    {
      name: <p>Spend</p>,
      selector: (row) => Math.round(row.spend),
      columnName: "spend",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Math.round(row?.spend))}
        </span>
      ),
    },
    {
      name: <p>{source === 'youtube' ? "Reported sales" : 'Sales'}</p>,
      selector: (row) => row.sales,
      columnName: "sales",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.sales))}
        </span>
      ),
    },
    {
      name: <p>Click</p>,
      selector: (row) => row.click,
      columnName: "click",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.click))}
        </span>
      ),
    },
    {
      name: <p>ROAS</p>,
      selector: (row) => row.roas,
      columnName: "roas",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.roas))}
        </span>
      ),
    },
    {
      name: <p>CPA</p>,
      selector: (row) => row.cpa,
      columnName: "cpa",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(roundupValues(row?.cpa))}
        </span>
      ),
    },
    {
      name: <p>CPM</p>,
      selector: (row) => row.cpm,
      columnName: "cpm",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.cpm))}
        </span>
      ),
    },
    {
      name: <p>CPC</p>,
      selector: (row) => row.cpc,
      columnName: "cpc",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.cpc))}
        </span>
      ),
    },
    {
      name: <p>CTR %</p>,
      selector: (row) => row.ctr,
      columnName: "ctr",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.ctr))}
        </span>
      ),
    },
    {
      name: <p>CR %</p>,
      selector: (row) => row.cr,
      columnName: "cr",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(Number(row?.cr))}
        </span>
      ),
    },
    {
      name: <p>AOV</p>,
      selector: (row) => row.aov,
      columnName: "aov",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {transformToInternationalNumber(roundupValues(row?.aov))}
        </span>
      ),
    },
    {
      name: (
        <div style={{ display: "flex" }} className="react-table-head-col">
          <p style={{}}>Engage rate %</p>
          <span id="Engage-rate-info" style={{ marginLeft: "5px" }}>
            <IoInformationCircleOutline size={15} />
          </span>
        </div>
      ),
      selector: (row) => row.engage_rate,
      columnName: "engage_rate",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {row?.engage_rate &&
            transformToInternationalNumber(Number(row?.engage_rate))}
        </span>
      ),
    },
    {
      name: (
        <div style={{ display: "flex" }} className="react-table-head-col">
          <p style={{}}>Hook rate %</p>
          <span id="hook-rate-info" style={{ marginLeft: "5px" }}>
            <IoInformationCircleOutline size={15} />
          </span>
        </div>
      ),
      selector: (row) => row.hookrate,
      cell: (row) =>
        row?.hookrate && transformToInternationalNumber(Number(row?.hookrate)),

      columnName: "hookrate",
      sortable: true,
    },
    {
      name: (
        <div style={{ display: "flex" }} className="react-table-head-col">
          <p style={{}}>Complete rate%</p>
          <span id="complete-rate-info" style={{ marginLeft: "5px" }}>
            <IoInformationCircleOutline size={15} />
          </span>
        </div>
      ),
      selector: (row) => row.completion_rate,
      cell: (row) =>
        row?.completion_rate &&
        transformToInternationalNumber(Number(row?.completion_rate)),
      columnName: "completion_rate",
      sortable: true,
    },
    {
      name: (
        <div style={{ display: "flex" }} className="react-table-head-col">
          <p style={{}}>CPNV</p>
          <span id="CPNV-rate-info" style={{ marginLeft: "5px" }}>
            <IoInformationCircleOutline size={15} />
          </span>
        </div>
      ),
      selector: (row) => row.cpnv,
      columnName: "cpnv",
      sortable: true,
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {row?.cpnv === 'n/a' ? "n/a" : transformToInternationalNumber(Number(row?.cpnv))}
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const handleSort = (column, direction) => {
    let { columnName } = column;
    setSortDirection(direction);
    setSortBy(columnName);
  };

  const injectCustomRow = (pageData) => {
    if (pageData?.length > 0) {
      return [
        ...pageData,
        { ...netResultValues, selectable: false, sortable: false },
      ];
    }
    return pageData;
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const pageData = data ? data?.slice(startIndex, endIndex) : [];

  const paginatedData = injectCustomRow(pageData);


  useEffect(() => {
    // Check if data is falsy, clear arrays only once
    if (!data && !isFetching && filterCreativeText.length === 0) {

      if (creativeCards?.length || selectedRows.length) {
        setCreativeCards([]);
        setSelectedRows([]);
      }
    }

    if (data && data?.length === 0) {
      // Only update if creativeCards or selectedRows are not already empty
      setCreativeCards((prevCards) => {
        if (prevCards?.length > 0) {
          return [];
        }
        return prevCards;
      });

      setSelectedRows((prevSelected) => {
        if (prevSelected?.length > 0) {
          return [];
        }
        return prevSelected;
      });
    } else if (data && data?.length > 0) {
      // Filter the data to match selected rows
      const creativeCardsArray = data.filter((item) =>
        selectedRows?.includes(item.id)
      );


      // Only update creativeCards if the new array is different
      setCreativeCards((prevCards) => {
        const prevIds = prevCards.map((i) => i.id);
        const newIds = creativeCardsArray.map((i) => i.id);

        if (prevIds?.length === newIds?.length) return prevCards;

        if (JSON.stringify(prevIds) !== JSON.stringify(newIds)) {
          return creativeCardsArray;
        }
        return prevCards;
      });
      // Update selectedRows if it has changed
      setSelectedRows((prevSelected) => {
        const newSelectedIds = creativeCardsArray.map((i) => i.id);
        // if (newSelectedIds.length === prevSelected.length) return prevSelected

        if (JSON.stringify(prevSelected) !== JSON.stringify(newSelectedIds)) {
          return newSelectedIds;
        }
        return prevSelected;
      });
    }
  }, [data, selectedRows]);

  const isSelectedCard = useRef(false);

  useEffect(() => {
    if (filterCreativeText.length > 0 || filterType !== "all") {
      isSelectedCard.current = true;
    } else {
      isSelectedCard.current = false;
      if (selectedCardz.length > 0 && filterCreativeText.length === 0) {

        setSelectedRows(selectedCardz);
        setSelectedCardz([]);
      }
    }



    const filtered = filteredData(
      tableData?.creative,
      filterCreativeText,
      filterType
    );

    if (filtered) {
      setFilteredCreativeData(filtered);
      if (filterType !== 'all') {
        let defaultTopFour = getDefaultSelectedRows(filtered)
        setSelectedRows(defaultTopFour)
        setCreativeCards(() => data.filter((i) => defaultTopFour.includes(i)))
        // console.log('getDefaultSelectedRows(filtered) ', getDefaultSelectedRows(filtered))
        // console.log('creativeCards ', creativeCards)
      }
    }

  }, [filterType, filterCreativeText]);

  useEffect(() => {
    if (
      (isSelectedCard.current && selectedCardz.length === 0) &&
      filterType !== "all"
    ) {

      setSelectedCardz(selectedRows);
    }
    if (filterCreativeText.length > 0 && selectedCardz.length === 0) {
      setSelectedCardz(selectedRows);
    }
  }, [isSelectedCard.current, filterType, filterCreativeText]);

  if (source === 'youtube') {
    const removeColumnForYoutube = ['revenue', 'roas', 'cr'];
    // const removeColumnForYoutube = [];
    columns = columns.filter((col) => !removeColumnForYoutube.includes(col?.columnName));
  }


  return (
    <>
      <Tooltip
        anchorSelect="#hook-rate-info"
        content="Hook Rate = vv3s / Impression"
        place="top"
        positionStrategy="fixed"
        className="tooltip"
      />
      <Tooltip
        anchorSelect="#CPNV-rate-info"
        content="CPNV = Spend / Engagement"
        place="top-end"
        positionStrategy="fixed"
        className="tooltip"
      />
      <Tooltip
        anchorSelect="#complete-rate-info"
        content="Completion Rate = Complete video / vv3s"
        place="top"
        positionStrategy="fixed"
        className="tooltip"
      />
      <Tooltip
        anchorSelect="#Engage-rate-info"
        content="Engage Rate = Engagement / Impression"
        place="top"
        positionStrategy="fixed"
        className="tooltip"
      />

      <div
        className="creative-data-table"
        style={{
          borderRadius: "6px",
          background: "#1B1C1E",
          width: "100%",
          margin: "auto",
          padding: "20px 20px 0 20px",
        }}
      >
        <div className="creative-header">
          <CreativeSearch
            handleSelectChange={handleSelectChange}
            filterType={filterType}
            setFilterType={setFilterType}
            filterCreativeText={filterCreativeText}
            setFilterCreativeText={setFilterCreativeText}
          />
        </div>
        
        <DataTable
          columns={columns}
          data={paginatedData} // Updated to use paginatedData
          customStyles={customStyles}
          pagination
          paginationServer // Added for server-side pagination
          paginationTotalRows={data?.length}
          onChangePage={handlePageChange} // Handles page change
          onChangeRowsPerPage={handleRowsPerPageChange} // Handles rows per page change
          paginationRowsPerPageOptions={[10, 15,20,25,30]} // Add rows per page options
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage} // Use rowsPerPage state for the current selection
        
          onSort={handleSort}
          sortServer={true}
        />
      </div>
    </>
  );
};

export default CreativeTable;
