import React, { Suspense, useEffect, useState } from "react";
import "./styles.css";
import { bellLogo } from "../../assets";
import { DatePicker, DropDownComp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setDashDate } from "../../redux/reducers/dateSlice";
import { fetchDashboardGraphData } from "../../redux/reducers/dashboardGraphDataSlice";
import { fetchDashboardTrafficData } from "../../redux/reducers/dashboardTrafficDataSlice";
import { fetchDashboardMetricData } from "../../redux/reducers/dashboardMetricDataSlice";
import { FinalDashboardSectionLoader } from "../../components/loadingComponent/SkeletonLoader";
// import DashboardSection from "./DashboardSection";
import ProfileDropDown from './../../components/profileDropDown/ProfileDropDown';
const DashboardSection = React.lazy(() => import("./DashboardSection"));

const Dashboard = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [compareToDates, setCompareToDates] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const { dashdate } = useSelector((state) => state.date);
  const { isFetching, dashboardGraphData } = useSelector(
    (state) => state.dashboardGraphData
  );
  const { isFetching: isMetricFetching, dashboardMetricData } = useSelector(
    (state) => state?.dashboardMetricData
  );
  const { isFetching: isTrafficDataFetching, dashboardTrafficData } =
    useSelector((state) => state.dashboardTrafficData);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch dashboard data based on the selected dates
    if (dashdate.length === 2) {
      dispatch(
        fetchDashboardGraphData({
          startdate: dashdate[0],
          enddate: dashdate[1],
        })
      );
      dispatch(
        fetchDashboardTrafficData({
          startdate: dashdate[0],
          enddate: dashdate[1],
        })
      );
      dispatch(
        fetchDashboardMetricData({
          startdate: dashdate[0],
          enddate: dashdate[1],
        })
      );

      // Calculate the Compare To Dates
      calculateCompareToDates(dashdate[0], dashdate[1]);
    }
  }, [dashdate]);

  // Function to calculate the "Compare To" dates based on the selected date range
  const calculateCompareToDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffDays = (end - start) / (1000 * 60 * 60 * 24); // Difference in days

    // Calculate the Compare To dates
    const compareStart = new Date(start);
    compareStart.setDate(compareStart.getDate() - diffDays - 1);

    const compareEnd = new Date(end);
    compareEnd.setDate(compareEnd.getDate() - diffDays - 1);

    // Format dates for display: Month Date, Year
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    setCompareToDates([
      dateFormatter.format(compareStart),
      dateFormatter.format(compareEnd),
    ]);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const updateSelectedDates = (dates) => {
    setSelectedDates(dates);
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDates([]);
  };

  const isLoading = isFetching || isTrafficDataFetching;

  return (
    <div className="dashboard-container">
      <div className="dashboard-header-section">
        <span className="header-start-section">
          <h1>Dashboard</h1>

          <div className="drop-down-css">
            <DropDownComp />
          
          </div>

          <div className="header-end-section">
            <div className="multi-date-selected">
              <DatePicker
                openPopup={openPopup}
                date={dashdate}
                selectedDates={selectedDates}
                isPopupOpen={isPopupOpen}
                closePopup={closePopup}
                updateSelectedDates={updateSelectedDates}
                cancelSelectedDates={cancelSelectedDates}
                setDate={setDashDate}
              />
              <div className="inside-compare-container">
                <strong>Compare To :</strong>{" "}
                {compareToDates.length === 2
                  ? `${compareToDates[0]} - ${compareToDates[1]}`
                  : "Loading..."}
              </div>
            </div>
            {/* <div className="bell-icon"> */}
              {/* <img src={bellLogo} alt="bell" /> */}
              <ProfileDropDown />
            {/* </div> */}
          </div>
        </span>
      </div>
      {isLoading ? (
        <FinalDashboardSectionLoader />
      ) : (
        <div className="dashboard-body-section">
          <Suspense fallback={<FinalDashboardSectionLoader />}>
            <DashboardSection title="Overall" graphData={dashboardGraphData} />

            {!isMetricFetching &&
              dashboardMetricData &&
              dashboardMetricData?.length > 0 &&
              dashboardMetricData.map((section, i) => {
                if (!section?.data) return null;

                if (section.title === "meta" && section.accountPresent) {
                  return (
                    <DashboardSection
                      key={i}
                      title="Meta"
                      graphData={section.data}
                    />
                  );
                }

                if (section.title === "google" && section.accountPresent) {
                  return (
                    <DashboardSection
                      key={i}
                      title="Google"
                      graphData={section.data}
                    />
                  );
                }

                if (section.title === "adspend") {
                  return (
                    <DashboardSection
                      key={i}
                      title="AdSpend"
                      graphData={section.data}
                    />
                  );
                }

                return null;
              })}

            {dashboardTrafficData && (
              <DashboardSection
                title="Others"
                graphData={dashboardTrafficData}
              />
            )}
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default React.memo(Dashboard);
