import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./styles.css";
import { signIn } from "../../redux/reducers/authSlice";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { isMobile } from "react-device-detect";
import { useMediaQueries, useMediaQuery } from "@react-hook/media-query";
import { userLogout } from "../../services/api/authApis";

const SignIn = () => {
  const { isError, error, isFetching } = useSelector((state) => state.auth);
  // console.log(isMobile);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);

  // useEffect(() => {
  //   if (SmallScreen) {
  //     navigate("/message"); // Navigate to /message if it's a small screen
  //   }
  // }, [SmallScreen, navigate]);

  // Redirect if the user is on a mobile device
  const SmallScreen = useMediaQuery("(max-width: 500px)");

  // useEffect(() => {
  //   if (SmallScreen) {
  //     navigate("/message"); // Navigate to /message if it's a small screen
  //   }
  // }, [SmallScreen, navigate]);

  // Redirect if the user is on a mobile device

  const validationSchema = Yup.object().shape({
    signinEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    signinPassword: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      signinEmail: "",
      signinPassword: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(
        signIn({
          username: values.signinEmail,
          password: values.signinPassword,
        })
      )
        .then((res) => {
          // console.log(res);
          if (res.payload.status && res.payload.status === 200) {
            formik.resetForm();
            // navigate("/reportingpage");
            if (SmallScreen) {
              // userLogout();
              navigate("/message"); // Navigate to /message if it's a small screen
            } else {
              if (res.payload.data.user_id === null) {
                localStorage.setItem('t_workSpaceId', undefined)
                navigate("/client-profile");
              } else {
                navigate("/reportingpage");
              }
            }
          }
        })
        .catch((error) => {
          // Handle login error
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form className="signin-form-container" onSubmit={formik.handleSubmit}>
        <h1 className="signin-heading">Sign in to your account</h1>
        <div className="form-flex">
          <div className="form-starting">
            <div className="input-box-container">
              <label className="signin-label" htmlFor="signin-email">
                {formik.errors.signinEmail && formik.touched.signinEmail
                  ? ""
                  : "Email"}
              </label>
              <span id="signinEmailSpan" className="error">
                {" "}
                {formik.errors.signinEmail &&
                  formik.touched.signinEmail &&
                  formik.errors.signinEmail}
              </span>
              <br />
              <div className="input-field-with-icon-signin">
                <input
                  type="email"
                  value={formik.values.signinEmail}
                  name="signinEmail"
                  className="signin-input-field"
                  id="signin-email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <MdEmail className="input-icon-signin" />
              </div>
            </div>

            <div
              className="input-box-container"
              style={{ position: "relative" }}
            >
              <div className="password-error-container">
                <label className="signin-label" htmlFor="signin-password">
                  {formik.errors.signinPassword && formik.touched.signinPassword
                    ? ""
                    : "Password"}
                  <span id="signinPwSpan" className="error">
                    {formik.errors.signinPassword &&
                      formik.touched.signinPassword &&
                      formik.errors.signinPassword}
                  </span>
                </label>
                <br />
                <Link className="text-dec-none" to={"/forgot-password"}>
                  <p className="error-msg">Forgot your password?</p>
                </Link>
              </div>
              <input
                type={showPswd ? "text" : "password"}
                value={formik.values.signinPassword}
                name="signinPassword"
                className="signin-input-field"
                id="signin-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span
                className="login-pwd-wiew"
                onClick={() => setShowPswd(!showPswd)}
              >
                {showPswd ? <IoMdEyeOff className="eye-open" /> : <IoMdEye />}
              </span>
            </div>

            <p className="error" id="error-msg">
              {isError && error ? error.message : null}
            </p>

            <button
              type="submit"
              className="signin-button"
              disabled={formik.isSubmitting}
            >
              {isFetching ? "Signing in..." : "Sign in"}
            </button>
          </div>

          <Link className="signup-link" to="/sign-up">
            <p className="msg">Don't have an account?</p>
            <span className="msg-signup">Signup</span>
          </Link>
        </div>
      </form>
    </>
  );
};

export default SignIn;
