import React, { useEffect, useRef } from "react";
import { FiCopy } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoIosArrowBack, IoMdVideocam } from "react-icons/io";
import { googleAdsLogo } from "../../assets";
import { useDispatch } from "react-redux";
import { toastSetter } from "../../components/ToastContainer";
// import { deleteGoogleAccount } from "../../services/api/integrationApis";

const GoogleAdsFetched = (props) => {
  const {
    setGadsaccnt,
    googleAdAccounts,
    listAccessibleCustomers,
    popupHandler,
  } = props;

  const dispatch = useDispatch();
  const scriptRef = useRef(null); // Ref for the code block

  // Function to copy UTM parameters to clipboard
  const copyGoogleUtm = () => {
    const utmText = `ad_source=google&ad_id={creative}&campaign_id={campaignid}`; // UTM parameters

    // Create a temporary textarea to copy the text
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = utmText; // Set the value to UTM text
    document.body.appendChild(tempTextarea);
    tempTextarea.select(); // Select the text
    document.execCommand("copy"); // Execute copy command
    document.body.removeChild(tempTextarea); // Remove the textarea
    const infoMessage = "Utm copied to clipboard!"; // Success message
    toastSetter("info", infoMessage); // Display toast notification
  };

  useEffect(() => {
    if (googleAdAccounts === undefined) dispatch(setGadsaccnt(false));
  }, [googleAdAccounts]);

  // Function to handle Google account deletion
  //  const handleDeleteGoogleAccount = (accountId) => {
  //   const workspace = "yourWorkspace"; // Set your workspace value or pass it dynamically
  //   deleteGoogleAccount({ id: accountId })
  //     .then((res) => {
  //       console.log(res)
  //       // toastSetter("success", "Google account deleted successfully");
  //       // Add any additional logic, such as refreshing the list of accounts
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       // toastSetter("error", `Failed to delete Google account: ${error}`);
  //     }).finally(

  //     )
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Back button to navigate to the previous screen */}
        <button
          onClick={() => dispatch(setGadsaccnt(false))}
          className="back-button"
        >
          <IoIosArrowBack /> Back
        </button>{" "}
        {/* Video icon to open a popup for Google UTM */}
        <IoMdVideocam
          className="onboard-video-icon onboard-video-icon-google-utm"
          style={{ position: "unset", float: "none" }}
          onClick={() => popupHandler("google-utm")}
        />
      </div>
      <h2 className="integration-app-heading">Google Ads Accounts</h2>

      <div
        className="integration-app-container-gads"
        style={{ marginBottom: "80px" }}
      >
        {googleAdAccounts &&
          googleAdAccounts.accounts.length > 0 &&
          googleAdAccounts.accounts.map((gadsaccnt, i) => (
            <div className="integration-app-cards-gads" key={i}>
              {/* Display account name */}
              <button
                className="button-delete-google"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                // onClick={() => handleDeleteGoogleAccount(gadsaccnt.accountid)} // Call delete on button click
              >
                <MdDelete size={24} color="white" />
              </button>
              <h2 style={{ color: "#fff" }}>{gadsaccnt?.accountname}</h2>
              <p
                style={{
                  color: "#fff",
                  fontSize: "30px",
                  fontWeight: "600",
                }}
              >
                {/* Display account ID */}
                {gadsaccnt?.accountid}
              </p>
              {/* Button to reconnect to the Google Ads account */}
              <button
                className="app-button-reconnect"
                onClick={() => listAccessibleCustomers("radio", gadsaccnt?.id)}
              >
                Reconnect
              </button>
            </div>
          ))}

        {/* Show a card to connect with Google AdWords if less than 3 accounts are present */}
        {googleAdAccounts &&
          googleAdAccounts.accounts.length < 3 &&
          [...Array(3 - googleAdAccounts.accounts.length)].map((_, i) => (
            <div
              className="integration-app-cards-gads"
              style={{ cursor: "pointer" }}
              onClick={() => listAccessibleCustomers("radio")}
              key={i}
            >
              <img
                src={googleAdsLogo}
                alt="google-ads"
                style={{ width: "45px" }} // Logo size
              />

              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "18.603px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "white",
                }}
              >
                Connect with
                <br /> Google adwords
              </p>
              <h2 style={{ color: "#fff", fontSize: "16px" }}>
                Add an Account
              </h2>
              <div className="plus">
                <img className="plus-icon" src="/Group 2910.svg" />{" "}
                {/* Plus icon for adding an account */}
              </div>
            </div>
          ))}
      </div>
      <h4
        style={{
          fontSize: "18px",
          color: "#fff",
        }}
      >
        URL Parameter Google :
      </h4>
      <div
        className="code-container"
        style={{
          width: "40vw",
          justifyContent: "space-between",
          minWidth: "max-content",
        }}
      >
        <pre ref={scriptRef} className="code">
          <code>
            {"\n"}
            {"ad_source=google&ad_id={creative}&campaign_id={campaignid}\n"}
            {"\n"}
          </code>
        </pre>

        {/* Button to copy UTM parameters */}
        <button onClick={copyGoogleUtm} className="copy-button">
          <FiCopy scale={0.8} size={22} /> {/* Copy icon */}
        </button>
      </div>
    </>
  );
};

export default GoogleAdsFetched;
