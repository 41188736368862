import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGoogleAdsAccounts } from "../../services/api/integrationApis";

const initialState = {
  googleAdAccounts: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
};

export const fetchGoogleAdAccounts = createAsyncThunk(
  "googleAdAccounts/fetchGoogleAdAccounts",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getGoogleAdsAccounts(values);
      if (response.status === 200) {
        return response.data;
      } 
      // else throw new Error("Failed to fetch google ad accounts");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

// const formatAccounts = (data) => {
//   return Object.entries(data.accounts).flatMap(([key, values]) => {
//     console.log(values);
//     // Use a Set to remove duplicates from values
//     const uniqueValues = [...new Set(values)];
//     return uniqueValues.map((value) => ({
//       id: key,
//       accountname: value,
//       accountid: value,
//     }));
//   });
// };

export const googleAdAccountsSlice = createSlice({
  name: "googleAdAccounts",
  initialState,
  extraReducers: {
    [fetchGoogleAdAccounts.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchGoogleAdAccounts.fulfilled]: (state, { payload }) => {
      console.log("Payload:", payload); // Debugging line
      state.isFetching = false;
      state.isError = false;
      state.googleAdAccounts = payload;
      state.errorMessage = null;
    },
    [fetchGoogleAdAccounts.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export default googleAdAccountsSlice.reducer;
