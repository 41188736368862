import { useSelector } from "react-redux";
import PopUpTwo from "../../components/PopUpTwo/PopUpTwo";

function UserJourney({
  isOpen,
  closePopup,
  openPopup,
  setPopupOpentwo,
  setPopupOpen,
}) {
  const facebook = "/meta 2.png";
  const google = "/meta 2 (1).png";
  const social = "/social.png";

  const data = useSelector((state) => state.userJourney);

  // if (isOpen === false) {
  //   setPopupOpentwo(true);
  //   setPopupOpen(false);
  // }

  const formatDate = (date) => {
    const currentDate = new Date(date);
    const formattedDate4 = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    return formattedDate4;
  };

  const time = (time) => {
    const actualTime = time.split(" ");
    const newtime = actualTime[1].split("").splice(0, 5).join("");
    return newtime;
  };

  return (
    <>
      <PopUpTwo isOpen={isOpen} onClose={closePopup}>
        <div
          className="sales-popup-container2"
          style={{
            minHeight: "70vh",
            padding: "1.25rem",
            width: "100%",
            minWidth: "53.313rem",
          }}
        >
          <div className="sales-body">
            <div className="sales-body-maintitle">
              <img
                src="/Group 3194.png"
                alt="User Journey Title"
                style={{ height: "0.875rem", width: "0.9375rem" }}
              />
              <p>
                <span className="journey-text">
                  User Journey<span className="beta">(BETA)</span>
                </span>
              </p>
            </div>
            <div className="sales-body-main">
              <p className="sales-body-title">Tracking Code</p>
              <div className="fix-body">
                <div className="codes">
                  {data &&
                    data?.userdata.length > 0 &&
                    data?.userdata.map((item, index) => (
                      <div key={index}>
                        {" "}
                        {/* Key should be set to the outer div */}
                        <div className="main-box">
                          <div className="box">
                            <div>
                              {item.adsource.toLowerCase() === "google" ? (
                                <img
                                  className="user-image"
                                  src={google}
                                  alt="Google"
                                />
                              ) : item.adsource.toLowerCase() === "facebook" ? (
                                <img
                                  className="user-image-meta"
                                  src={facebook}
                                  alt="Facebook"
                                />
                              ) : (
                                <img
                                  className="user-image-default"
                                  src={social}
                                  alt="Social"
                                  style={{ width: "20px" }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="box-part">
                            <div className="datene">
                              {formatDate(item.event_time)}
                              <p className="smallest-line"></p>
                              {time(item.event_time)}
                            </div>
                            <div className="source">{item.adsource}</div>
                            <div className="ad-name">{item.adname}</div>
                          </div>
                        </div>
                        {/* Conditionally render small-line */}
                        {index !== data.userdata.length - 1 && (
                          <>
                            <p className="small-line"></p>
                            <p className="small-line"></p>
                            <p className="small-line"></p>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopUpTwo>
    </>
  );
}

export default UserJourney;
