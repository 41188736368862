import { useState } from 'react';
import './style.css'
import { userLogout } from '../../services/api/authApis';
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorComponent = ({ error }) => {
    const [showDetails, setShowDetails] = useState(false);

    const ErrorDetails = useRouteError()
    console.log('ErrorDetails ', ErrorDetails)
    const handleReload = () => {
        window.location.reload();  // This will reload the current page
    };

    const navigate = useNavigate();
    const signOutHandler = () => {
        userLogout();
        navigate("/sign-in");
    };

    return (
        <>
            <div className="error-container">
                <h1>😕 Oops! An Error Occurred</h1>
                <p>
                    We're sorry for the inconvenience. It looks like something went wrong on our end. Please try the following steps to resolve the issue:
                </p>
                <ol>
                    <li>🔄 - <span onClick={handleReload}>Refresh the page</span> to see if the issue persists.</li>
                    <li>🔑 - <span onClick={signOutHandler}>Sign out</span> and sign back in if you're logged in.</li>
                    <li>📞 - Contact our support team if the issue continues.You can reach us at <a href="mailto:contact@trackocity.io">contact@trackocity.io</a> .</li>
                </ol>
                <p>We appreciate your patience as we work to resolve this!</p>
            </div>
            <div className="error-details-bottom">
                <span onClick={() => { setShowDetails(!showDetails) }} className="toggle-details-btn">
                    {showDetails ? 'Hide Details' : 'Show Details'}
                </span>
                {error && showDetails && (
                    <div className="error-details" >
                        <h3 style={{ color: 'red' }}>Error Info</h3>
                        <h4 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Error Message: <pre>{error?.message}</pre></h4>
                        <h4>Error stack:</h4>
                        <pre>{error?.stack}</pre>
                    </div>
                )}
                {ErrorDetails && showDetails && (
                    <div className="error-details" >
                        <h3 style={{ color: 'red' }}>Error Info</h3>
                        <h4 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Error Message: <pre>{ErrorDetails?.message}</pre></h4>
                        <h4>Error stack:</h4>
                        <pre>{ErrorDetails?.stack}</pre>
                    </div>
                )}
            </div>
        </>

    );
};

export default ErrorComponent;