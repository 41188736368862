// routes.js

import { createBrowserRouter } from "react-router-dom";
import { AuthContainer, NavigateComponent, SideBar } from "../components";
import {
  Dashboard,
  Integration,
  NotFound,
  PrivacyPolicy,
  ReportingPage,
  SignIn,
  SignUp,
  Profile,
  TermsAndConditions,
  ScheduleDemoPage,
  Forgotpassword,
  ResetPasswordPage,
  Payment,
  Creative,


} from "../pages";
import ProtectedRoute from "./ProtectedRoute";
import Pricing from "../pages/PrivingPage.jsx/Pricing";
import Message from "../pages/Message/Message";
import EmailVerify from "../pages/EmailVerify/EmailVerify";
import AdMetric from "../components/CreativeComp/AdMetric";
import AdBreakdown from "../pages/Creatives/AdBreakdown";
import ClientProfile from "../pages/clientProfile/ClientProfile";
import Settings from "../pages/Settings/Settings";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import { logError } from "../utils";
// import PredictiveBehavior from "../pages/predictiveBehavior/PredictiveBehavior";



const authRoutes = [
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/forgot-password",
    element: <Forgotpassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
];

const appRoutes = [
  {
    path: "/reportingpage",
    element: <ReportingPage />,
    errorElement: <ErrorComponent />
  },
  // {
  //   path: "/predictiveBehaviour",
  //   element: <PredictiveBehavior />,
  //   errorElement: <ErrorComponent />
  // },
  {
    path: "/creatives",
    element: <Creative />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/integration",
    element: <Integration />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/client-profile",
    element: <ClientProfile />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/ad-breakdown",
    element: <AdBreakdown />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/settings",
    element: <Settings />,
    errorElement: <ErrorComponent />
  }
];

const routes = [
  {
    path: "/",
    element: <NavigateComponent navigatePath="/sign-in" />,
    children: [
      {
        element: (
          <ErrorBoundary
            fallbackRender={({ error }) => <ErrorComponent error={error} />}
            onError={logError}
          >
            <AuthContainer />
          </ErrorBoundary>
        ),
        children: authRoutes,
      },
    ],
  },
  {
    element: (
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponent error={error} />}
        onError={logError}
      >
        <ProtectedRoute />
        <SideBar />
      </ErrorBoundary>
    ),
    children: appRoutes,
  },

  {
    path: "/redirect-payment",
    element: <Payment />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/schedule-demo",
    element: <ScheduleDemoPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/message",
    element: <Message />,
  },
  {
    path: "/email-verify",
    element: <EmailVerify />,
  },
];

export const router = createBrowserRouter(routes);
