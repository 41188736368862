import React, { useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const EmailVerify = () => {
    const navigate = useNavigate();
  useEffect(() => {
    const redirectLink = "https://topmate.io/ankur_gupta92/1120982";
    const timer = setTimeout(() => {
        window.location.href = redirectLink;
    }, 9000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="email-verify-container">
      <span className="email-verify-span">
        <h2 className="email-verify-heading">Email Verification:</h2>
        <p className="email-verify-para">
          Your email has been successfully verified.
        </p>
      </span>
      <span className="email-verify-span">
        <h2 className="email-verify-heading">Onboarding:</h2>
        <p className="email-verify-para">
          For a smooth experience, we are redirecting you to book an onboarding
          call with us.
        </p>
      </span>
    </div>
  );
};

export default EmailVerify;
