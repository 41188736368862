import http from "./http";
import catchAsync from "../../utils/catchAsync";

export const getSalesVelocityTable = catchAsync(async (values) => {
  console.log("Hello");
  try {
    const { startdate, enddate, campaign, channel, include_dayzero } = values;

    const response = await http.get(`behaviour/sales_velocity`, {
      params: { startdate, enddate, campaign, channel, include_dayzero },
    });

    if (response.status === 200) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const getPredictiveTable = catchAsync(async (values) => {
  console.log("Hello");
  try {
    const { startdate, enddate, channel } = values;

    const response = await http.get(`/behaviour/campaigndetail`, {
      params: { startdate, enddate, channel },
    });

    if (response.status === 200) {
      console.log(response);
      return response; // Return response data
    }
  } catch (error) {
    // console.log(error);
    throw error;
  }
});
