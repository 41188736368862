import { useMediaQuery } from "@react-hook/media-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const LastReportLoader = () => {
  return (
    <div
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#1b1c1e" }}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={8} count={2} />
      </SkeletonTheme>
    </div>
  );
};

export const ReportDetailsLoader = () => {
  return (
    <div
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#1b1c1e" }}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton style={{ margin: "10px 0" }} height={10} />
        <table style={{ width: "100%" }}>
          <tbody>
            {[...Array(3)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(6)].map((_, colIndex) => (
                  <td key={colIndex}>
                    <Skeleton
                      width={"80%"}
                      height={10}
                      style={{ marginBottom: "10px" }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SkeletonTheme>
    </div>
  );
};

export const AreaChartLoader = () => {
  return (
    <div
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#1b1c1e" }}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"30%"} style={{ margin: "10px 0" }} />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "15px" }}
          count={8}
          height={5}
        />
      </SkeletonTheme>
    </div>
  );
};

export const DashBoardGraphLoader = ({ index }) => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        overflow: "hidden",
      }}
      className={`grid-item ${index === 1 ? "wide" : ""}`}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"100%"} style={{ margin: "10px 0" }} />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "18px" }}
          count={4}
          height={8}
        />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "15px" }}
          count={6}
          height={5}
        />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardGraphLoaderMini = ({ index }) => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        width: "100%",
        height: "100%",
      }}
      className={`grid-item-mini`}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"100%"} style={{ margin: "10px 0" }} />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "18px" }}
          count={4}
          height={8}
        />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "15px" }}
          count={6}
          height={5}
        />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardGraphLoaderTwo = ({ index }) => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        overflow: "hidden",
      }}
      className={`grid-item ${index === 1 ? "wide" : ""}`}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"30%"} style={{ margin: "10px 0" }} />
        <Skeleton
          width={"70%"}
          style={{ marginBottom: "18px" }}
          count={4}
          height={8}
        />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "15px" }}
          count={6}
          height={5}
        />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardGraphLoaderThree = ({ index }) => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        maxHeight: "fit-content",
        overflow: "hidden",
      }}
      className={`grid-item ${index === 1 ? "wide" : ""}`}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton
          width="100%"
          height={25}
          style={{ marginBottom: "8px" }}
          count={2}
        />
        <Skeleton
          width={"80%"}
          style={{ margin: "12px auto" }}
          count={3}
          height={10}
        />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "10px" }}
          count={2}
          height={12}
        />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardGraphLoaderFour = ({ index }) => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        // paddingLeft: "0px",
        // paddingRight: "150px",
        overflow: "hidden",
      }}
      className={`grid-item ${index === 1 ? "wide" : ""}`}
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"30%"} style={{ margin: "10px 0" }} />
        <Skeleton
          width={"70%"}
          style={{ marginBottom: "18px" }}
          count={4}
          height={8}
        />
        <Skeleton
          width={"100%"}
          style={{ marginBottom: "15px" }}
          count={6}
          height={5}
        />
      </SkeletonTheme>
    </div>
  );
};

// export const DashBoardTrafficLoader = () => {
//   return (
//     <div
//       style={{
//         backgroundColor: "#1b1c1e",

//       }}
//       className="tile-skeleton-wrapper"
//     >
//       <SkeletonTheme baseColor="#202020" highlightColor="#444">
//         <Skeleton width={"30%"} style={{ margin: "5px 0" }} />
//         <Skeleton
//           width={"70%"}
//           style={{ marginBottom: "5px" }}
//           count={1}
//           height={8}
//         />
//         <Skeleton
//           width={"100%"}
//           style={{ marginBottom: "5px" }}
//           count={1}
//           height={5}
//         />
//       </SkeletonTheme>
//     </div>
//   );
// };

export const DashBoardTrafficLoader = () => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        paddingLeft: "20px",
        paddingBottom: "120px",
        paddingRight: "20px",

        borderRadius: "9px",
        width: "100%", // Adjust width as needed
        maxWidth: "365px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={10} style={{ marginBottom: "10px" }} />
        <Skeleton height={10} style={{ marginBottom: "10px" }} />
        <Skeleton height={10} style={{ marginBottom: "10px" }} />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardTrafficLoaderTwo = () => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        paddingLeft: "120px",
        paddingTop: "100px",
        paddingBottom: "40px",
        paddingRight: "150px",
        marginBottom: "-3px",
        borderRadius: "9px",
        width: "100%", // Adjust width as needed
        maxWidth: "800px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardTrafficLoaderThree = () => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        paddingLeft: "40px",
        paddingTop: "50px",
        paddingBottom: "40px",
        paddingRight: "150px",
        marginBottom: "-3px",
        borderRadius: "9px",
        width: "100%", // Adjust width as needed
        maxWidth: "800px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
      </SkeletonTheme>
    </div>
  );
};
export const DashBoardTrafficLoaderFour = () => {
  return (
    <div
      style={{
        backgroundColor: "#1b1c1e",
        paddingLeft: "100px",
        paddingTop: "10px",

        paddingRight: "100px",

        borderRadius: "9px",
        width: "100%", // Adjust width as needed
        maxWidth: "800px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
        <Skeleton height={20} style={{ marginBottom: "10px" }} />
      </SkeletonTheme>
    </div>
  );
};

export const OrderDetailsLoader = () => {
  return (
    <div
      style={{
        backgroundColor: "inherit",
        height: "50%",
        width: "100%",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton width={"100%"} height={3} />
        <Skeleton width={"100%"} count={1} height={3} />
        <Skeleton width={"100%"} count={1} height={3} />

        {/* <Skeleton width={"30%"} style={{ margin: "5px 0" }} /> */}
        {/* <Skeleton
          width={"70%"}
          style={{ marginBottom: "5px" }}
          count={1}
          height={8}
        /> */}
      </SkeletonTheme>
    </div>
  );
};

export const SettingsTableLoaderTag = () => {
  return (
    <div
      className="creative-data-table"
      style={{
        padding: "20px",
        minHeight: "250px",
        width: "100%",
        margin: "0 auto",
        backgroundColor: "#1b1c1e",
      }}
    >
      <SkeletonTheme
        duration={2}
        baseColor="#202020"
        highlightColor="rgba(180, 180, 180, 0.2)"
      >
        <table style={{ width: "100%" }}>
          <tbody>
            {[...Array(3)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(4)].map((_, colIndex) => (
                  <td key={colIndex} style={{ paddingRight: "20px" }}>
                    <Skeleton
                      width={"100%"}
                      height={25}
                      style={{ marginBottom: "15px", marginRight: "30px" }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SkeletonTheme>
    </div>
  );
};
export const SettingsTableLoaderDatabase = () => {
  return (
    <div
      className="creative-data-table"
      style={{
        padding: "20px",
        minHeight: "250px",
        width: "100%",
        margin: "0 auto",
        backgroundColor: "#1b1c1e",
      }}
    >
      <SkeletonTheme
        duration={2}
        baseColor="#202020"
        highlightColor="rgba(180, 180, 180, 0.2)"
      >
        <table style={{ width: "100%" }}>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(4)].map((_, colIndex) => (
                  <td key={colIndex} style={{ paddingRight: "20px" }}>
                    <Skeleton
                      width={"100%"}
                      height={25}
                      style={{ marginBottom: "15px", marginRight: "30px" }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SkeletonTheme>
    </div>
  );
};
export const CreativeTableLoader = () => {
  return (
    <div
      className="creative-data-table"
      style={{
        borderRadius: "10px",
        padding: "20px",
        minHeight: "650px",
        width: "100%",
        margin: "0 auto",
        backgroundColor: "#1b1c1e",
      }}
    >
      <SkeletonTheme
        duration={2}
        baseColor="#202020"
        highlightColor="rgba(180, 180, 180, 0.2)"
      >
        <Skeleton style={{ margin: "15px 0" }} height={50} />

        <table style={{ width: "100%" }}>
          <tbody>
            {[...Array(11)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(8)].map((_, colIndex) => (
                  <td key={colIndex}>
                    <Skeleton
                      width={"100%"}
                      height={25}
                      style={{ marginBottom: "15px" }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SkeletonTheme>
    </div>
  );
};

export const CreativeCardLoader = () => {
  return (
    <div
      style={{
        backgroundColor: "inherit",
        // height: "50%",
        width: "100%",
        minWidth: "220px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme
        baseColor="#202020"
        highlightColor="rgba(180, 180, 180, 0.2)"
      >
        <Skeleton width={"100%"} height={12} />
        <Skeleton width={"100%"} count={1} height={8} />
        <Skeleton width={"100%"} count={1} height={8} />
        <Skeleton width={"100%"} count={1} height={8} />
        <Skeleton width={"100%"} count={1} height={8} />
      </SkeletonTheme>
    </div>
  );
};
export const CreativeChartLoader = () => {
  return (
    <div
      style={{
        backgroundColor: "inherit",
        // height: "50%",
        width: "100%",
        minWidth: "220px",
      }}
      className="tile-skeleton-wrapper"
    >
      <SkeletonTheme
        baseColor="#202020"
        highlightColor="rgba(180, 180, 180, 0.2)"
      >
        <Skeleton width={"100%"} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
        <Skeleton width={"100%"} count={1} height={20} />
      </SkeletonTheme>
    </div>
  );
};

export const DashboardSectionLoader = ({ title }) => {
  const isFinalScreen = useMediaQuery("(min-width: 1600px)");

  const isLargeScreen = useMediaQuery(
    "(min-width: 1500px) and (max-width: 1599px"
  );
  const isTabScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-width: 1000px) and (max-width: 1499px)"
  );

  return (
    <div className="dashboard-section section-bg-style">
      <div className="section-container">
        <div className="section-header">
          <h3 className="grid-item-section-header">{title}</h3>
        </div>
      </div>
      <div className="section-body">
        <div className="grid-container">
          {isFinalScreen &&
            [...Array(4)].map((_, index) => (
              <DashBoardGraphLoader key={index} index={index} />
            ))}
          {isLargeScreen &&
            [...Array(4)].map((_, index) => (
              <DashBoardGraphLoaderTwo key={index} index={index} />
            ))}
          {isMediumScreen &&
            [...Array(4)].map((_, index) => (
              <DashBoardGraphLoaderThree key={index} index={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export const FinalDashboardSectionLoader = () => {
  return (
    <div className="dashboard-body-section">
      <DashboardSectionLoader title="Overall" />
      {/* <DashboardSectionLoader title="Adspend" /> */}
      <DashboardSectionLoader title="Others" />
    </div>
  );
};
