import React, { useEffect, useState } from "react";
import "./styleDrop.css";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyData, setClient } from "../../redux/reducers/agencySlice";
import DropDownHOC from "./DropDownHOC";
import useAgencyHandler from "../../hooks/useAgencyHandler";

const DropDownComp = (props) => {
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { reRenderPage } = useAgencyHandler();

  const { agencyAccounts, currentClient, loading, error } = useSelector(
    (state) => state.agency
  );

  useEffect(() => {
    async function setClientAccount() {
      try {
        if (agencyAccounts.length === 0) {
          dispatch(getAgencyData());
        }
        await agencyAccounts;
        const findAccount = await agencyAccounts?.find(
          (acc) => acc?.workspace === workSpaceId
        );
        if (findAccount) {
          dispatch(setClient(findAccount));
        }
      } catch (error) {
        console.log("error");
      }
    }
    if (!currentClient) {
      setClientAccount();
    }
  }, [dispatch, agencyAccounts, currentClient]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (workspaceId) => {
    const selectedAccount = agencyAccounts.find(
      (account) => account.workspace === workspaceId
    );
    if (selectedAccount) {
      // setClearConnected(true);
      localStorage.setItem("clearConnect", true);
      dispatch(setClient(selectedAccount));
      reRenderPage();
      setIsOpen(false);
    }
  };

  return (
    <div className="custom-dropdown-container">
      <div className="custom-dropdown-header" onClick={toggleDropdown}>
        {currentClient?.name || "Select Client"}
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}></span>
      </div>
      {isOpen && (
        <ul className="custom-dropdown-list">
          {loading && <li>Accounts loading...</li>}
          {!loading &&
            agencyAccounts
              .filter((acc) => acc.workspace !== currentClient?.workspace)
              .map((acc) => (
                <li
                  key={acc.workspace}
                  onClick={() => handleOptionClick(acc.workspace)}
                >
                  {acc.name}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

export default DropDownHOC(DropDownComp);
