import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import toast, { Toaster } from "react-hot-toast";
import "./style.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { searchLogo } from "../../assets";
import { FiEdit } from "react-icons/fi";
import ToggleSwitch from "./Toggle";
import {
  fetchProducts,
  deleteExistingProduct,
  createNewProduct,
  updateExistingProduct,
  fetchTaxRate,
  saveTaxRate,
} from "../../redux/reducers/productSlice";
import ProductForm from "./ProductForm";
import {
  CreativeTableLoader,
  SettingsTableLoaderDatabase,
} from "../../components/loadingComponent/SkeletonLoader";
import { IoMdClose } from "react-icons/io";
import TagDetails from "./TagDetails";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
import { DropDownComp } from "../../components";
import { toastSetter } from "../../components/ToastContainer";

const Settings = () => {
  const dispatch = useDispatch();
  const {
    products = [],
    loading,
    error,
    taxRate,
    taxLoading,
    taxError,
  } = useSelector((state) => state.products);

  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(isModalOpen);
  const [newProduct, setNewProduct] = useState({
    product_name: "",
    cost_price: "",
    sale_price: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "Database"
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isTaxEditable, setIsTaxEditable] = useState(true);
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const { currentClient } = useSelector((state) => state.agency);
  const [toEditProd, setToEditProd] = useState([]);
  const [taxPercentage, setTaxPercentage] = useState("");
  const [isTaxInputEditable, setIsTaxInputEditable] = useState(false);
  const [hoveredEditButtonId, setHoveredEditButtonId] = useState(null); // To track hovered Edit button
  const [clickedEditButtonId, setClickedEditButtonId] = useState(null); // To track clicked Edit button
  const [isTaxEditing, setIsTaxEditing] = useState(false);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch, currentClient]);
  useEffect(() => {
    dispatch(fetchTaxRate())
      .then((response) => {
        if (response && response.payload !== undefined) {
          setTaxPercentage(parseFloat(response?.payload?.taxrate));
          setIsTaxEditable(response?.payload?.tax_on); // Set to true if tax rate is available
        } else {
          setTaxPercentage("");
          // toast.error("Tax rate not available.");
        }
      })
      .catch((err) => {
        // setTaxPercentage(""); // Set to empty string in case of an error
        toastSetter("error", "Error fetching tax rate.");
      });
  }, [dispatch, currentClient]);

  const handleTaxToggle = () => {
    // Allow toggle only if tax rate is initially not set
    setIsTaxEditable((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleOpenModal = (product = null) => {
    setErrorMessage(""); // Reset error message on opening modal
    if (product && product.id) {
      setIsEditing(true);
      setEditProductId(product.id);
      setNewProduct({
        product_name: product.product_name || "",
        cost_price: product.cost_price || "",
        sale_price: product.sale_price || "",
      });
    } else {
      setIsEditing(false);
      setEditProductId(null);
      setNewProduct({ product_name: "", cost_price: "", sale_price: "" });
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewProduct({ product_name: "", cost_price: "", sale_price: "" });
    setIsEditing(false);
    setErrorMessage(""); // Reset error message on modal close
    setClickedEditButtonId(null);
  };

  const handleAddOrUpdateProduct = (values) => {
    setErrorMessage("");

    if (isEditing && editProductId) {
      dispatch(updateExistingProduct({ id: editProductId, ...values }))
        .then(() => {
          dispatch(fetchProducts());
        })
        .catch((err) => toastSetter("error", "Error updating product"));
    } else {
      dispatch(createNewProduct(values)).then(() => dispatch(fetchProducts()));
    }

    handleCloseModal();
  };

  const handleDelete = (id) => {
    if (id) {
      dispatch(deleteExistingProduct(id))
        .then(() => {
          dispatch(fetchProducts()); // Refresh products
          toastSetter("success", "Product deleted successfully!");
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
          toastSetter("error", "Error deleting product");
        });
    }
  };

  const handleTabClick = (tabName) => setActiveTab(tabName);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const handleTaxPercentageChange = (e) => {
    let value = e.target.value;

    // Remove any character that is not a digit or a decimal point
    value = value.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point is allowed
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }

    setTaxPercentage(value);
  };

  const handleSaveUpdates = () => {
    setIsTaxEditing(false); // Reset the Edit button color to white after saving
    setIsTaxInputEditable((prev) => !prev);
    setIsTaxInputEditable((prev) => !prev);

    if (
      taxRate?.tax_on === isTaxEditable &&
      Number(taxRate?.taxrate) == taxPercentage
    ) {
      toastSetter("error", "Taxrate values are same as previous.");
      return;
    }

    // Only proceed if the tax rate has been edited and has a valid new value
    if (taxPercentage !== null) {
      const originalTaxRate = Number(taxRate?.taxPercentage); // Store the original tax rate before attempting the update

      if (taxPercentage !== taxRate) {
        // Proceed only if tax rate differs
        dispatch(
          saveTaxRate({
            tax_on: isTaxEditable,
            taxrate: parseFloat(taxPercentage),
          })
        )
          .then((response) => {
            if (response.payload === 200) {
              dispatch(fetchTaxRate()); // Refresh the tax rate to ensure it's saved
              toastSetter("success", "Successfully updated tax rate");
            } else {
              // Show error and reset taxPercentage to original value
              toastSetter("error", "Failed to update tax rate");
              setTaxPercentage(Number(taxRate?.taxrate)); // Revert to the original tax rate immediately
            }
          })
          .catch((error) => {
            console.error("Error updating tax rate:", error);
            toastSetter("error", "Error updating tax rate.");
            setTaxPercentage(originalTaxRate); // Reset to original tax rate in case of an error
            // Reset to original tax rate in case of an error
          });
      } else {
        toastSetter("info", "Tax rate is already up-to-date.");
      }
    }
  };

  const filteredProducts = products?.filter((product) => {
    const productName = product?.product_name || "";
    return productName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleTaxEdit = () => {
    setIsTaxEditing((prev) => !prev); // Toggle the edit mode color
    setIsTaxInputEditable((prev) => !prev); // Toggle input editability
  };

  return (
    <div className="setting-container">
      {/* Position the Toaster centrally */}
      {/* <Toaster position="top-center" reverseOrder={false} /> */}

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <div className="setting-header-section" style={{ paddingRight: "60px" }}>
        <div className="client-heading">
          <h1 className="profile-heading">Settings</h1>
          <DropDownComp />
          <ProfileDropDown />
        </div>
      </div>

      <div className="profile-body-section-settings">
        <div className="one-container">
          <div className="button-tag-database">
            <div
              className={`tab-button ${activeTab === "Tag" ? "active" : ""}`}
              onClick={() => handleTabClick("Tag")}
            >
              Tag
            </div>
            <div
              className={`tab-button ${
                activeTab === "Database" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Database")}
            >
              Database
            </div>
          </div>

          <div className="setting-profile-container">
            {activeTab === "Database" && (
              <>
                <div className="below-comp">
                  <divsojdfjsdflkjlk
                    className="upper-comp"
                    // style={{
                    //   marginTop: "-44px",
                    //   marginBottom: "32px",
                    //   marginLeft: "-30px",
                    // }}
                  >
                    <div className="search-button-container">
                      <img src={searchLogo} alt="search" />
                      <input
                        className="search-button"
                        style={{ width: "300px" }}
                        placeholder="Product Name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      {searchQuery && (
                        <IoMdClose
                          className="clear-search-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearch}
                        />
                      )}
                    </div>

                    <div className="common-comp-toggle-tax">
                      <div className="toggle-switch">
                        <ToggleSwitch
                          isToggled={isTaxEditable}
                          onToggle={handleTaxToggle}
                        />
                      </div>

                      <div className="tax-percent">
                        Tax Rate (%):
                        <input
                          type="text" // Changed from "number" to "text"
                          className="tax-box"
                          style={{
                            color: "white",
                            margin: "auto",
                            textAlign: "center",
                            fontSize: "1em",
                            outline: isTaxInputEditable && "1px solid",
                          }}
                          value={taxPercentage}
                          onChange={handleTaxPercentageChange}
                          disabled={!isTaxInputEditable}
                          autoFocus={isTaxInputEditable}
                        />
                        <FiEdit
                            onClick={() => {
                            handleTaxEdit(); // Call the function to toggle color and input editability
                            }}
                            size={20}
                            style={{ cursor: "pointer" }}
                            color={isTaxEditing ? "yellow" : "white"} // Changes color based on isTaxEditing state
                        />
                      </div>
                    </div>
                  </divsojdfjsdflkjlk>

                  <div className="item-cover">
                    <divksjdlfj className="item-boxs">
                      <div
                        className="item-box-mini"
                        style={{
                          color: "#d5d900",
                          fontWeight: "bold",
                        }}
                      >
                        <div className="item-box-mini-text-head">
                          <p className="mini-text-settings">Product Name</p>
                          <p className="mini-text-settings">Cost</p>
                          <p className="mini-text-settings">Price</p>
                        </div>
                        <div className="hide"></div>
                        <div className="hide"></div>
                      </div>
                    </divksjdlfj>
                  </div>

                  <div className="item-box-settings-database">
                    {loading ? (
                      <SettingsTableLoaderDatabase />
                    ) : error ? (
                      <p>{error}</p>
                    ) : filteredProducts && filteredProducts.length > 0 ? (
                      filteredProducts
                        .filter((product) => product && product.id) // Ensure product and id are defined
                        .map((product) => (
                          <div className="item-box-mini" key={product.id}>
                            <div className="item-box-mini-text">
                              <p className="mini-text-settings">
                                {product?.product_name || "Unknown"}
                              </p>
                              <p className="line"></p>
                              <p className="mini-text-settings">
                                {product?.cost_price || "N/A"}
                              </p>
                              <p className="line"></p>
                              <p className="mini-text-settings">
                                {product?.sale_price || "N/A"}
                              </p>
                            </div>
                            <button
                              className="profile-btn-edit"
                              onMouseEnter={() =>
                                setHoveredEditButtonId(product.id)
                              } // Set hovered product ID
                              onMouseLeave={() => setHoveredEditButtonId(null)} // Reset on mouse leave
                              onClick={() => {
                                handleOpenModal(product); // Open the modal when clicked
                                setClickedEditButtonId(product.id); // Mark as clicked
                              }}
                            >
                              <FiEdit
                                size={20}
                                color={
                                  clickedEditButtonId === product.id ||
                                  hoveredEditButtonId === product.id
                                    ? "yellow"
                                    : "white"
                                } // Change color based on hover or click
                              />
                            </button>

                            <button
                              className="profile-btn-delete"
                              onClick={() => handleDelete(product.id)}
                            >
                              <RiDeleteBin6Line size={20} color="white" />
                            </button>
                          </div>
                        ))
                    ) : (
                      <p>No products available</p>
                    )}
                  </div>

                  <div className="button-container-settings">
                    <button
                      className="btn add-btn"
                      onClick={() => handleOpenModal()}
                    >
                      Add
                    </button>
                    <button
                      className="btn save-btn"
                      onClick={handleSaveUpdates}
                    >
                      Save the Updates
                    </button>
                  </div>
                </div>
              </>
            )}

            {activeTab === "Tag" && (
              <TagDetails
                toEditProd={toEditProd}
                setToEditProd={setToEditProd}
              />
            )}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{isEditing ? "Edit Product" : "Add New Product"}</h2>

            <ProductForm
              initialValues={newProduct}
              onSubmit={handleAddOrUpdateProduct}
              onCancel={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
