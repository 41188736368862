// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
// import { Link } from "react-router-dom";
// import { Outlet } from "react-router-dom";
// import { useSelector } from "react-redux"; // Import useSelector
// import {
//   dashboardLogo,
//   dashboardLogoDark,
//   integrationLogo,
//   integrationLogoDark,
//   profileLogo,
//   profileLogoDark,
//   reportingLogo,
//   reportingLogoDark,
//   trackocityLogo,
//   creativeLogo,
//   creativeLogoDark,
//   setupLogo,
//   setupLogoDark,
// } from "../../assets";
// import "./styles.css";

// const SideBar = () => {
//   const location = useLocation();
//   const [activeMenu, setActiveMenu] = useState(null);
//   const [collapsed, setCollapsed] = useState(true);

//   const clients = useSelector((state) => state.agency.agencyAccounts); // Get clients from Redux

//   useEffect(() => {
//     const index = menuData.findIndex(
//       (menuItem) => menuItem.path === location.pathname
//     );
//     setActiveMenu(index);
//   }, [location.pathname]);

//   const handleMenuClick = (index) => {
//     setActiveMenu(index);
//     setCollapsed(true);
//   };

//   const menuData = [
//     {
//       label: "Dashboard",
//       icon: dashboardLogo,
//       iconDark: dashboardLogoDark,
//       path: "/dashboard",
//     },
//     {
//       label: "Reporting",
//       icon: reportingLogo,
//       iconDark: reportingLogoDark,
//       path: "/reportingpage",
//     },
//     {
//       label: "Creatives",
//       icon: creativeLogoDark,
//       iconDark: "Group 3077 dark.png",
//       path: "/creatives",
//     },
//     {
//       label: "Integration",
//       icon: integrationLogo,
//       iconDark: integrationLogoDark,
//       path: "/integration",
//     },
//     {
//       label: "Settings",
//       icon: setupLogo,
//       iconDark: setupLogoDark,
//       path: "/settings",
//     },
//   ];

//   const [display, setDisplay] = useState("none");

//   useEffect(() => {
//     // Show sidebar if there's at least 1 client, otherwise hide it
//     const agencyId = localStorage.getItem("t_agency_id");
//     if (agencyId) {
//       if (clients.length > 0) {
//         setDisplay("block");
//       } else {
//         setDisplay("none");
//       }
//     } else {
//       setDisplay("block");
//     }
//   }, [clients?.length]); // Trigger useEffect when the clients array length changes

//   return (
//     <div className="main-container">
//       <Sidebar
//         className="sidebar"
//         backgroundColor="#1b1c1e"
//         rootStyles={{
//           minWidth: collapsed ? "unset" : "220px",
//           height: "100%",
//           borderRight: "4px solid #cdac00",
//           zIndex: 99,
//           position: "fixed",
//           top: 0,
//           left: 0,
//         }}
//         style={{ display: display }}
//         collapsedWidth={collapsed ? "78px" : "220px"}
//         collapsed={collapsed}
//         transitionDuration={700}
//         onMouseEnter={() => setCollapsed(false)}
//         onMouseLeave={() => setCollapsed(true)}
//       >
//         <Menu
//           rootStyles={{
//             color: "#FF7B00",
//             backgroundColor: "#1b1c1e",
//             fontFamily: "Roboto",
//             fontSize: "20px",
//             fontWeight: "700",
//             padding: "0 15px",
//           }}
//           menuItemStyles={{
//             button: ({ active }) => ({
//               color: active ? "#221F1D" : undefined,
//               backgroundColor: active ? "#FF7B00" : undefined,
//               alignItems: "center",
//               justifyContent: "center",
//               borderRadius: "6px",
//               paddingRight: "27px",
//             }),
//           }}
//         >
//           <img
//             src={trackocityLogo}
//             className={`trackocity-logo ${
//               collapsed ? "trackocity-logo-collapsed" : ""
//             }`}
//             alt="logo"
//           />
//           {menuData.map((menuItem, index) => (
//             <Link
//               to={menuItem.path}
//               key={index}
//               className={`text-dec-none ${
//                 collapsed ? "" : "ps-menu-button-fit"
//               }`}
//             >
//               <MenuItem
//                 icon={
//                   <img
//                     src={
//                       activeMenu === index ? menuItem.iconDark : menuItem.icon
//                     }
//                     className="menu-icon"
//                     alt="logo"
//                   />
//                 }
//                 onClick={() => handleMenuClick(index)}
//                 active={activeMenu === index}
//               >
//                 {menuItem.label}
//               </MenuItem>
//             </Link>
//           ))}
//         </Menu>
//       </Sidebar>
//       <div style={{ width: "78px", display: display }}></div>
//       <div className="data-container">
//         <Outlet />
//       </div>
//     </div>
//   );
// };

// export default SideBar;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./styles.css";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import {
  dashboardLogo,
  dashboardLogoDark,
  integrationLogo,
  integrationLogoDark,
  profileLogo,
  profileLogoDark,
  reportingLogo,
  reportingLogoDark,
  trackocityLogo,
  creativeLogo,
  creativeLogoDark,
  setupLogo,
  setupLogoDark,
  behaviourlogo,
  behaviourlogodark,

} from "../../assets";

const SideBar = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const [collapsed, setCollapsed] = useState(true); // State to track sidebar collapse

  useEffect(() => {
    // Find the index of the menu item whose path matches the current location
    const index = menuData.findIndex(
      (menuItem) => menuItem.path === location.pathname
    );
    setActiveMenu(index);
  }, [location.pathname]);

  const handleMenuClick = (index) => {
    setActiveMenu(index);
    setCollapsed(true);
  };

  const menuData = [
    {
      label: "Dashboard",
      icon: dashboardLogo,
      iconDark: dashboardLogoDark,
      path: "/dashboard",
    },
    {
      label: "Reporting",
      icon: reportingLogo,
      iconDark: reportingLogoDark,
      path: "/reportingpage",
    },
    {
      label: "Creatives",
      icon: creativeLogoDark,
      iconDark: "Group 3077 dark.png",
      path: "/creatives",
    },
    // {
    //   label: "Behaviour",
    //   icon: creativeLogoDark,
    //   iconDark: "Group 3077 dark.png",
    //   path: "/predictivebehaviour",
    // },
    {
      label: "Integration",
      icon: integrationLogo,
      iconDark: integrationLogoDark,
      path: "/integration",
    },
    // {
    //   label: "Profile",
    //   icon: profileLogo,
    //   iconDark: profileLogoDark,
    //   path: "/profile",
    // },
    {
      label: "Settings",
      icon: setupLogo,
      iconDark: setupLogoDark,
      path: "/settings",
    },
  ];

  return (
    <div className="main-container">
      <Sidebar
        className="sidebar"
        backgroundColor="#1b1c1e"
        rootStyles={{
          minWidth: collapsed ? "unset" : "220px", // Set the width based on collapse state
          height: "100%",
          // width: "max-content",
          borderRight: "4px solid #cdac00",
          zIndex: 99,
          position: "fixed",
          top: 0,
          left: 0,
        }}
        // collapsedWidth="78px"
        collapsedWidth={collapsed ? "78px" : "220px"}
        collapsed={collapsed} // Set the collapsed state
        transitionDuration={700}
        onMouseEnter={() => setCollapsed(false)} // Toggle the sidebar on mouse enter
        onMouseLeave={() => setCollapsed(true)} // Toggle the sidebar on mouse leave
      >
        <Menu
          rootStyles={{
            color: "#FF7B00",
            backgroundColor: "#1b1c1e",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            padding: "0 15px",
            
          }}
          menuItemStyles={{
            button: ({ active }) => {
              return {
                color: active ? "#221F1D" : undefined,
                backgroundColor: active ? "#FF7B00" : undefined,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
                paddingRight: "27px",
                
                };
            },
          }}
        >
          <img
            src={trackocityLogo}
            className={`trackocity-logo ${
              collapsed ? "trackocity-logo-collapsed" : ""
            }`}
            alt="logo"
            // onClick={toggleSidebar}
          />
          {menuData.map((menuItem, index) => (
            <React.Fragment key={index}>
              <Link
                to={menuItem.path}
                key={index}
                
                className={`text-dec-none ${
                  index === menuData.length - 1 && collapsed === false
                    ? "last-item"
                    : index === menuData.length - 1 && collapsed === true
                    ? "last-item-collapsed"
                    : ""
                } ${collapsed === false ? "ps-menu-button-fit" : ""}`}
              >
                <div>
                  <MenuItem
                    icon={
                      <img
                        src={
                          activeMenu === index
                            ? menuItem.iconDark
                            : menuItem.icon
                        }
                        className={
                          index === menuData.length - 1
                            ? "menu-icon-two"
                            : "menu-icon"
                        }
                        alt="logo"                        
                      />
                    }
                    onClick={() => handleMenuClick(index)}
                    active={activeMenu === index}
                  >
                    {menuItem.label}
                  </MenuItem>
                </div>
              </Link>
            </React.Fragment>
          ))}
        </Menu>
      </Sidebar>
      <div style={{ width: "78px" }}></div>
      <div
        // className={`data-container
        // ${collapsed ? "data-container-collapsed" : ""}`}
        className={"data-container"}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default SideBar;
