//changes
export function transformToInternationalNumber(number) {
  return number.toLocaleString("en-US")
}

export function capitalizeFirstLetter(str) {
  // Convert the first character to uppercase and the rest to lowercase
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function simplifyNumber(number) {
  if (number === 0) return "0"; // If the number is 0, return "0"

  const abbreviations = ["", "k", "m", "b", "t"]; // Abbreviations for thousand, million, billion, trillion, etc.
  const order = Math.floor(Math.log10(Math.abs(number)) / 3); // Calculate the order of magnitude

  // Divide the number by the appropriate power of 10 and round it to the nearest whole number
  const roundedNumber = Math.round(number / Math.pow(10, order * 3));

  // Append the corresponding abbreviation
  return roundedNumber + " " + abbreviations[order];
}

export const truncateString = (str, maxLength) => {
  if (!str) return ""; // Handle null or undefined strings
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

// Function to format date string from "Month DD YYYY" to "YYYY-MM-DD"
export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because January is 0
  let day = dateObj.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function formatNumber(input) {
  // Convert input to a number
  let num = parseFloat(input);

  if (isNaN(num)) {
    return 0;
  }

  // Check if the number is an integer
  if (Number.isInteger(num)) {
    return num;
  }

  // Round to 2 decimal places and convert to string
  let roundedNum = num.toFixed(2);

  // Convert to number again to remove trailing zeros
  return parseFloat(roundedNum);
}

// utils.js

export const normalizePath = (location, navigate) => {
  // Normalize the path by removing trailing slashes and clearing search and hash
  const normalizedPath = location.pathname.replace(/\/+$/, '');
  if (location.pathname !== normalizedPath || location.search || location.hash) {
    navigate(normalizedPath, { replace: true });
  }
};


export const roundupValues = (value) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return 0;  // Handle invalid values
  }
  if (value === 0) {
    return 0
  }

  // Return 2 decimal places if less than 100, otherwise remove decimals
  return value < 100 ? Number(value.toFixed(2)) : Math.round(value);
};

// Function to get data from local storage
export const getLocalStorageItem = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

// Function to set data in local storage
export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};


export const calculateComparison = (value, compValue, secondFilterLength) => {
  if (secondFilterLength === 0) return null;

  if (compValue === -1 || compValue === 0) return null;

  const comparison = formatNumber(((value - compValue) / Math.abs(compValue)) * 100);


  return comparison === 0 ? null : parseFloat(comparison.toFixed(1))
};


export const logError = (error) => {
  console.log('ErrorBoundary Error: ', error)
}