// src/components/Integration/CodeTab.js

import React, { useEffect, useRef, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { IoMdVideocam } from "react-icons/io";
import { getIntegration } from "../../services/api/integrationApis";
import { toastSetter } from "../../components/ToastContainer";
import { useSelector } from "react-redux";
// import { startTour } from "../../redux/reducers/joyrideSlice";

const CodeTab = (props) => {
  const {
    // isPopupOpen,
    // setIsPopupOpen,
    // popupChildren,
    // setPopupChildren,
    // dispatch,
    popupHandler,
  } = props;
  const { currentClient } = useSelector((state) => state.agency);
  console.log(currentClient);

  // State variables to hold product ID and subdomain
  const [productId, setProductId] = useState(null);
  const [subDomain, setSubDomain] = useState(null);
  const scriptRef = useRef(null); // Reference for the script code element

  // Effect to fetch integration details when the component mounts
  useEffect(() => {
    getIntegration().then((res) => {
      // Set product ID and subdomain if available in the response
      if (res && res.productid !== null) {
        setProductId(res.productid);
      }
      if (res && res.subdomain !== null) {
        setSubDomain(res.subdomain);
      }
    });
  }, [currentClient]);

  // Function to copy the generated tracking script to clipboard
  const copyScript = () => {
    const scriptText = `
      <script>
        var head = document.head;
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "https://${subDomain}.trackocity.io/${productId}";
        head.appendChild(script);
      </script>
    `;

    // Create a temporary textarea to facilitate copying to clipboard
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = scriptText; // Set the script text as the value
    document.body.appendChild(tempTextarea); // Append to the body
    tempTextarea.select(); // Select the textarea content
    document.execCommand("copy"); // Execute copy command
    document.body.removeChild(tempTextarea); // Remove the textarea after copying
    const infoMessage = "Script copied to clipboard!";
    toastSetter("info", infoMessage); // Show success message
  };

  //   const handleCopyScript = () => {
  //     const steps = [
  //       {
  //         target: ".onboard-video-icon-copy-section-video",
  //         content: "Click to see tutorial video",
  //         placement: "bottom",
  //         disableBeacon: true,
  //       },
  //     ];
  //     dispatch(startTour(steps)); // Start a guided tour (commented out)
  //   };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ul className="integration-app-heading">Steps</ul>
        {/* Video icon to trigger popup for tutorial video */}
        <IoMdVideocam
          className="onboard-video-icon onboard-video-icon-copy-section-video"
          style={{ position: "unset", float: "none" }}
          onClick={() => popupHandler("Code Section Video")} // Handle video popup
        />
      </div>

      {/* Instruction to copy tracking code */}
      <li style={{ fontSize: "18px", color: "#fff" }}>
        Copy your personalized tracking code
      </li>
      <div className="code-container">
        {/* Display the generated tracking script */}
        <pre ref={scriptRef} className="code">
          <code>
            {"<script>\n"}
            {"  var head = document.head;\n"}
            {"  var script = document.createElement('script');\n"}
            {"  script.type = 'text/javascript';\n"}
            {`  script.src = "https://${subDomain}.trackocity.io/${productId}";\n`}
            {"  head.appendChild(script);\n"}
            {"</script>"}
          </code>
        </pre>

        {/* Button to copy the script to clipboard */}
        <button onClick={copyScript} className="copy-button">
          <FiCopy scale={0.8} size={22} />
        </button>
      </div>
      {/* Instruction for pasting the tracking code */}
      <li style={{ fontSize: "18px", color: "#fff" }}>
        Paste Trackocity's tracking code in the header of your website just
        above the closing <br /> <code>&lt;/head&gt;</code> element on every
        page of your website
      </li>
    </>
  );
};

export default CodeTab;
