import React from "react";
import { FcGoogle } from "react-icons/fc";
import { fbLogo } from "../../assets";

const TrafficSourceCell = ({ row }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "2px",
          width: "max-content",
        }}
      >
        {row.src}
        {row.trafficSource}
      </div>
    </div>
  );
};

export default TrafficSourceCell;
