import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { transformToInternationalNumber } from "../../utils";

// Helper function to render reporting cells
const RenderReportingCell = (row, fieldName, comparisonFieldName, openPopup) => {

    let value = row[fieldName];
    const comparisonValue = row[comparisonFieldName];

    const displayValue = () => {
        if (fieldName === "Revenue" || fieldName === 'Spend') {
            if (value !== -1 && value != '-1' && value !== null && value !== undefined) {
                value = Math.round(value);
            }
        }

        return value == -1 || value === '-1' || value === null || value === undefined
            ? 'n/a'
            : (transformToInternationalNumber(value))
    }

    const handleClick = () => {
        if (fieldName === 'Sales' && value > 0 && openPopup) {
            openPopup(row.id);
        }
    };



    const getColor = () => {
        // If it's CPAComparison, change the color logic
        if (comparisonFieldName === 'CPAComparison' || comparisonFieldName === 'nCPAComparison') {
            return comparisonValue > 0 ? 'red' : '#00E723';
        }
        // Default color logic
        return comparisonValue < 0 ? 'red' : '#00E723';
    };

    return (
        <div className="reporting-cell">
            <span
                title={value}
                onClick={handleClick}
                className={
                    fieldName === 'Sales' && value > 0
                        ? 'sales-row'
                        : fieldName === 'Sales'
                            ? 'sales-row-disabled'
                            : undefined
                }
            >
                {displayValue()}
            </span>
            {comparisonValue !== null && comparisonValue !== undefined && (
                <span
                    title={comparisonValue}
                    style={{
                        fontSize: '11px',
                        color:
                            comparisonValue === '-' ||
                                typeof comparisonValue !== 'number' ||
                                comparisonValue < 0
                                ? 'red'
                                : '#00E723',
                    }}
                >
                    {comparisonValue === '-' ? (
                        <span style={{ color: 'red' }}>-</span>
                    ) : comparisonValue < 0 ?
                        <>
                            <BsArrowDown style={{ color: getColor() }} />
                            <span style={{ color: getColor() }}>{`(${comparisonValue}%)`}</span>
                        </>
                        :
                        <>
                            <BsArrowUp style={{ color: getColor() }} />
                            <span style={{ color: getColor() }}>{`(${comparisonValue}%)`}</span>
                        </>
                    }
                </span>
            )}
        </div>
    );
};


export default RenderReportingCell